import "./offerpackage.css";
import * as React from "react";
import { useLocation } from "react-router-dom"; // Import the hook

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import Typography from "@mui/material/Typography";
import Header from "../Includes/Header";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import PropTypes from "prop-types";

import OfferPackageMember from "./OfferPackageMember";
import OfferPackage from "./OfferPackage";

import { useNavigate } from "react-router-dom";

const drawerWidth = 292;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

function ParentOfferPackage() {
  const navigate = useNavigate();
  const location = useLocation(); // Access location
  const [value, setValue] = React.useState(0);
  React.useEffect(() => {
    // If tabIndex is passed in the location state, set it to the tab value
    if (location.state?.tabIndex !== undefined) {
      setValue(location.state.tabIndex);
    }
  }, [location.state]);
  const handleChangetab = (event, newValue) => {
    setValue(newValue);
  };

  const drawer = (
    <div className="d-flex flex-column h-100">
      <Header />
    </div>
  );
  React.useEffect(() => {
    const storedData = localStorage.getItem("userdata");
    if (!storedData) {
      // User is not logged in, no need to redirect
      navigate('/');
    }
    
  }, []);
  const tabStyle = (isActive) => ({
    textDecoration: "none",
    flexGrow: 1,
    textTransform: "none",
    fontWeight: "bold",
    marginRight: "10px",
    backgroundColor: isActive ? "#D8E2FF" : "inherit",
    borderRadius: "10px 10px 0px 0px",
    borderBottom: isActive ? "2px solid #005AC1" : "none",
    color: isActive ? "#005AC1" : "inherit",
  });
  return (
    <Box sx={{ display: "flex", height: "100vh", backgroundColor: "#EFEDF1" }}>
      <CssBaseline />
      <Box>
        <Header />
      </Box>

      <Box
        component="main"
        sx={{ flexGrow: 1, overflowY: "auto", padding: "20px" }}
      >
        <div style={{ display: "flex", flexGrow: 1, marginTop: "50px" }}>
          <div style={{ flex: 1, marginTop: "10px" }}>
            <Box sx={{ flexGrow: 1 }}>
              <Box
                sx={{
                  borderBottom: 1,
                  marginLeft: "25px",
                  borderColor: "divider",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChangetab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab
                    sx={{
                      ...tabStyle(0 === value),
                    }}
                    label="Offer Package Member"
                    {...a11yProps(0)}
                  />
                  <Tab
                    sx={{
                      ...tabStyle(1 === value),
                    }}
                    label="Offer Package"
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
            </Box>

            <TabPanel value={value} index={0}>
              <OfferPackageMember />
            </TabPanel>

            <TabPanel value={value} index={1}>
              <OfferPackage />
            </TabPanel>
          </div>
        </div>
      </Box>
    </Box>
  );
}

export default ParentOfferPackage;
