import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import AppBar from "@mui/material/AppBar";
import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import { ButtonGroup } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Header from "../Includes/Header";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { DialogContentText } from "@mui/material";
import {
  Card,
  Button,
  Grid,
  TextField,
  Snackbar,
  FormControl,
  Alert,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Paper,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Menu from "@mui/material/Menu";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CircularProgress from "@mui/material/CircularProgress";
import json2mq from "json2mq";
import {
  retrieveAdminUser,
  AutocompleteAdminUser,
  DeleteAdminUser,
  StatusAdminUser,
  UpdateAdminUser,
} from "../Apihelper/ApiHelperUser";
import {
  RetriveOfferPackage,
  DeleteOfferpackageUser,
} from "../Apihelper/ApiOfferPackage";
import {
  UpdateOfferPackage,
  DeleteOfferpackage,
  AutocompleteOfferPackage,
} from "../Apihelper/ApiPackage";

import { useNavigate } from "react-router-dom";

const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 600,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide the card container on small screens (mobile view)
    },
  },
  card: {
    width: "100%",
    margin: "8px",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px", // Adjust the card width as needed
    },
  },
}));
const drawerWidth = 292;

function OfferPackage(props) {
  const navigate = useNavigate();
  const { window1 } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [status, setstatus] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [Role, setRole] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [options, setOptions] = useState([]);
  const [deleteConfirmationId, setDeleteConfirmationId] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // Default to 'success'
  const [fetchdatarow, setetchdatarow] = useState([]); // Default to 'success'
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const dateRef = useRef();
  const [age, setAge] = React.useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedData, setEditedData] = useState({
    id: "",
    name: "",
    value: "",
    description: "",
    quantity: "",
    duration: "",
  });
  const [submitted, setSubmitted] = useState(false);

  const handleEditModalOpen = (
    id,
    name,
    value,
    description,
    quantity,
    duration
  ) => {
    setEditedData({ id, name, value, description, quantity, duration });
    setEditModalOpen(true);
  };
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 600,
    })
  );
  React.useEffect(() => {
    const storedData = localStorage.getItem("userdata");
    if (!storedData) {
      // User is not logged in, no need to redirect
      navigate('/');
    }
    
  }, []);
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const handleToastClose = () => {
    setOpenToast(false);
  };
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenToast(true);
  };
  const container =
    window1 !== undefined ? () => window().document.body : undefined;

  // useEffect(() => {
  //   // Perform localStorage action
  //   const item = localStorage.getItem('userdata')
  //   if (item ==null){
  //      setredirectpage(true);
  //   }
  //   //console.log("tapan"+item);
  // }, [])
  // if (redirectpage) {

  //   return redirect('/');
  // }

  const handleConfirm = async () => {
    // setIsActive(!isActive);
    // setOpenConfirmation(false);

    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;

      const res = await StatusAdminUser(
        userToken,
        userId,
        status,
        deleteConfirmationId
      );

      if (res.code == "200") {
        handleSnackbarOpen(res.message, "success");
        setIsActive(!isActive);
        setOpenConfirmation(false);
        setOpenDeleteConfirmation(false);
        fetchData();
      } else {
        handleSnackbarOpen(res.message, "error");
        //  console.error(error); // Handle error
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };
  const handleCancel = () => {
    setOpenConfirmation(false);
  };
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const fetchData = async (value = "") => {
    setLoading(true);
    setHasMore(true);
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id; // Assuming you have a userId in userdata
      const searchText = value; // Example search text
      const limit = 10; // Example limit
      const index = 0; // Example index
      const res = await RetriveOfferPackage(
        userToken,
        userId,
        searchText,
        limit,
        index
      );

      if (res.code == "200") {
        setetchdatarow(res.data);
        console.log(res.data);
        setLoading(false);
      } else {
        handleSnackbarOpen(res.message, "error");
        // console.error(error); // Handle error
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleLoadMore = async () => {
    // Calculate the next index based on the number of items loaded so far
    const nextIndex = fetchdatarow.length;

    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);

    try {
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const searchText = "";
      const limit = 10;

      const res = await RetriveOfferPackage(
        userToken,
        userId,
        searchText,
        limit,
        nextIndex
      );

      if (res.code == "200") {
        // Append the newly loaded data to the existing data
        setetchdatarow((prevResults) => [...prevResults, ...res.data]);

        // Check if there are more results available
        if (res.data.length >= 0 && res.data.length < 10) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSearchChange = (event, value) => {
    const searchText = value || ""; // If value is undefined or empty, set searchText to empty string
    // setSearchText(searchText);
    // Call fetchData function here with the updated searchText
    fetchData(searchText);
  };

  const handleDelete = (id) => {
    handleClose();
    setDeleteConfirmationId(id);
    setOpenDeleteConfirmation(true, id);
  };

  const handleConfirmDelete = async (id) => {
    // setSnackbarMessage('package deleted successfully');
    // setSnackbarSeverity('error');
    // setOpenDeleteConfirmation(false);
    // setOpenToast(true); // Show toast message

    if (deleteConfirmationId !== null) {
      try {
        const userData = localStorage.getItem("userdata");
        const userDataObj = JSON.parse(userData);
        const userToken = userDataObj.token;
        const userId = userDataObj.id;

        const res = await DeleteOfferpackage(
          userToken,
          userId,
          deleteConfirmationId
        );

        if (res.code == "200") {
          handleSnackbarOpen(res.message, "success");
          setOpenDeleteConfirmation(false);
          fetchData();
        } else {
          handleSnackbarOpen(res.message, "error");
          //  console.error(error); // Handle error
        }
      } catch (error) {
        console.error(error);
        // Handle errors
      }
    }
  };

  const handleCancelDelete = () => {
    setOpenDeleteConfirmation(false);
  };
  const handleInputChange = async (value) => {
    if (value.length >= 3) {
      try {
        const userData = localStorage.getItem("userdata");
        const userDataObj = JSON.parse(userData);
        const userToken = userDataObj.token;
        const userId = userDataObj.id; // Assuming you have a userId in userdata
        const searchText = value; // Example search text

        const res = await AutocompleteOfferPackage(
          userToken,
          userId,
          searchText
        );

        if (res.code == "200") {
          const names = res.data.map((item) => item.search_text);
          setOptions(names);
        } else {
          handleSnackbarOpen(res.message, "error");
          //  console.error(error); // Handle error
        }
      } catch (error) {
        console.error(error);
        // Handle errors
      }
    }
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const handleEditSubmit = async () => {
    // Check if required fields are filled
    if (
      !editedData.name ||
      !editedData.value ||
      !editedData.description ||
      !editedData.quantity ||
      !editedData.duration
    ) {
      setSubmitted(true);
      return;
    }

    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;

    try {
      const res = await UpdateOfferPackage(
        userToken,
        editedData // Pass the entire editedData object
      );

      if (res.code === 200) {
        handleSnackbarOpen(res.message, "success");
        setEditedData({
          id: "",
          name: "",
          value: "",
          description: "",
          quantity: "",
          duration: "",
        });
        fetchData();
      } else {
        handleSnackbarOpen(res.message, "error");
      }
    } catch (error) {
      console.error(error);
      handleSnackbarOpen("An error occurred while updating", "error");
    }

    setEditModalOpen(false);
  };

  return (
    <Box>
      <Box>
        <div>
          <div>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography variant="h5" component="h6">
                  Members
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  onClick={() => navigate("/addofferpackage")}
                  sx={{
                    textTransform: "none",
                    whiteSpace: "nowrap",
                    fontWeight: 700,
                  }}
                >
                  Add Package
                </Button>
              </Box>
            </Box>

            <Box>
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={options}
                onInputChange={(event, value) => handleInputChange(value)} // Pass searchText to handleInputChange
                onChange={(event, value) => handleSearchChange(event, value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      width: 300,
                      "& .MuiInputLabel-root": {
                        textAlign: "center", // Center align the label text
                      },
                    }}
                    label="Search input"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
                sx={{
                  "& .MuiInputBase-root": {
                    alignItems: "center",
                    height: "40px",
                  },
                }}
              />
            </Box>

            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                overflow: "hidden",
                margin: "20px 0",
              }}
            >
              {loading ? ( // Display spinner if loading is true
                <div style={{ textAlign: "center", margin: "50px auto" }}>
                  <CircularProgress />
                </div>
              ) : (
                // Display table if loading is false
                <Table size="small" aria-label="simple table">
                  <TableHead sx={{ backgroundColor: "#B1ADAD" }}>
                    <TableRow
                      sx={{
                        backgroundColor: "#2f80ed",
                      }}
                    >
                      <TableCell
                        sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }}
                      >
                        NAME
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }}
                      >
                        DESCRIPTION
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }}
                      >
                        VALUE
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }}
                      >
                        QUANTITY
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }}
                      >
                        STATUS
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }}
                      >
                        ACTION&nbsp;
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fetchdatarow.map((row, indexx) => (
                      <TableRow
                        key={indexx}
                        sx={{
                          "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                          "&:hover": {
                            backgroundColor: "#e3f2fd",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          <Typography>{row.name}</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          <Typography>{row.description}</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                          }}
                          align="left"
                        >
                          {row.value}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                          }}
                          align="left"
                        >
                          {row.quantity}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                          }}
                          align="left"
                        >
                          {" "}
                          <Button
                            variant="outlined"
                            sx={{ fontWeight: 700, textTransform: "none" }}
                            color={row.status === 1 ? "primary" : "error"}
                          >
                            {row.status === 1 ? "Active" : "Inactive"}
                          </Button>
                        </TableCell>

                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          <>
                            <IconButton
                              color="primary"
                              aria-label="edit"
                              onClick={() =>
                                handleEditModalOpen(
                                  row.id,
                                  row.name,
                                  row.value,
                                  row.description,
                                  row.quantity, // Ensure your row object has this field
                                  row.duration // Ensure your row object has this field
                                )
                              }
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              color="error"
                              aria-label="delete"
                              onClick={() => handleDelete(row.id)} // Pass the id or any other identifier if needed
                            >
                              <DeleteIcon />
                            </IconButton>
                          </>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleLoadMore}
                disabled={
                  !hasMore ||
                  (fetchdatarow.length < 10 && fetchdatarow.length !== 0)
                }
                sx={{ fontWeight: "bold", textTransform: "none" }}
              >
                {fetchdatarow.length === 0 || fetchdatarow.length < 10
                  ? "No More Records"
                  : "Load More"}
              </Button>
            </div>
          </div>
        </div>
      </Box>
      <Dialog
        open={openDeleteConfirmation}
        onClose={handleCancelDelete}
        fullWidth="sm"
      >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this package?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<CancelIcon />}
            sx={{ fontWeight: "bold", textTransform: "none" }}
            onClick={handleCancelDelete}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            sx={{ fontWeight: "bold", textTransform: "none" }}
            variant="contained"
            startIcon={<DeleteIcon />}
            color="error"
          >
            {" "}
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openToast}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          variant="filled"
          onClose={handleToastClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={openConfirmation} onClose={handleCancel}>
        <DialogTitle>{isActive ? "Deactivate" : "Activate"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isActive
              ? "Are you sure you want to deactivate this item?"
              : "Are you sure you want to activate this item?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontWeight: "bold", textTransform: "none" }}
            startIcon={<CancelIcon />}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            sx={{ fontWeight: "bold", textTransform: "none" }}
            variant="contained"
            startIcon={<CheckCircleIcon />}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editModalOpen} onClose={handleEditModalClose}>
        <DialogTitle>Edit Offer Package</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ paddingX: "20px" }}>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <TextField
                  name="name"
                  label="Name"
                  placeholder="Enter Name"
                  value={editedData.name}
                  onChange={handleChange}
                  error={submitted && !editedData.name}
                  helperText={
                    submitted && !editedData.name && "Name is required"
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <TextField
                  name="value"
                  label="Value"
                  placeholder="Enter Value"
                  value={editedData.value}
                  onChange={handleChange}
                  error={submitted && !editedData.value}
                  helperText={
                    submitted && !editedData.value && "Value is required"
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <TextField
                  name="description"
                  label="Description"
                  placeholder="Enter Description"
                  value={editedData.description}
                  onChange={handleChange}
                  error={submitted && !editedData.description}
                  helperText={
                    submitted &&
                    !editedData.description &&
                    "Description is required"
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <TextField
                  name="quantity"
                  label="Quantity"
                  placeholder="Enter Quantity"
                  value={editedData.quantity}
                  onChange={handleChange}
                  error={submitted && !editedData.quantity}
                  helperText={
                    submitted && !editedData.quantity && "Quantity is required"
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <TextField
                  name="duration"
                  label="Duration"
                  placeholder="Enter Duration"
                  value={editedData.duration}
                  onChange={handleChange}
                  error={submitted && !editedData.duration}
                  helperText={
                    submitted && !editedData.duration && "Duration is required"
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontWeight: "bold", textTransform: "none" }}
            startIcon={<CancelIcon />}
            onClick={handleEditModalClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ fontWeight: "bold", textTransform: "none" }}
            startIcon={<CheckCircleIcon />}
            onClick={() => {
              setSubmitted(true);
              handleEditSubmit();
            }}
            variant="contained"
            color="primary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default OfferPackage;
