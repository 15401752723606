// Dashboard.js

import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import Header from "../Includes/Header";
import Sidebar from "../Includes/Sidebar";
import GraphChart from "./GraphChart.jsx";
import BarChart from "./BarChart.jsx";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Import the back arrow icon


import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Container,
  TablePagination,
  CssBaseline,
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
} from "@mui/material";
import PieChart from "./PieChart.jsx";
// import { Grid } from '@mui/material';
import React, { useEffect, useState } from "react";
const drawerWidth = 240;
import {
  getActivePT,
  getUpcomingExpire,
  getPendingDue,
} from "../Apihelper/Dashboard.js";
const steps = ["Step 1", "Step 2"]; // Define your steps here
const useCardStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  height: "95px", // Consistent height for all cards
  borderRadius: "12px",
  textAlign: "center", // Center align all text within the card
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s, box-shadow 0.3s",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0 6px 25px rgba(0, 0, 0, 0.15)",
  },
};
const useValueStyles = {
  fontSize: "16px", // Smaller font size for the values
  color: "#555",
};
const useTypographyStyles = {
  fontSize: "12px", // Smaller font size for the titles
  fontWeight: "bold",
  color: "#333",
};
const useTableStyles = {
  borderRadius: "10px",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
  marginTop: "10px",
};

const useTableHeadStyles = {
  backgroundColor: "#5cabeb",
};

const useTableCellStyles = {
  fontWeight: "bold",
  color: "#fff",
  textAlign: "center",
};

const useTableBodyCellStyles = {
  padding: "12px",
  textAlign: "center",
  color: "#333",
  fontSize: "14px",
};

const useTableRowStyles = {
  "&:hover": {
    backgroundColor: "#f5f5f5",
    transition: "background-color 0.3s",
  },
};
const Dashboard = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState(null); // State for storing error messages

  const navigate = useNavigate();
  useEffect(() => {
    const storedData = localStorage.getItem("userdata");
    if (!storedData) {
      // User is not logged in, no need to redirect
      navigate("/");
    }
  }, []);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0); // To hold the total count for pagination

  const [searchText, setSearchText] = useState(""); // Search input if any
  const [loading, setLoading] = useState(false); // Loading state
  const [datapending, setDatapending] = useState([]);
  const [pagepending, setPagepending] = useState(0);
  const [rowsPerPagepending, setRowsPerPagepending] = useState(5);
  const [totalCountpending, setTotalCountpending] = useState(0); // To hold the total count for pagination

  const [searchTextpending, setSearchTextpending] = useState(""); // Search input if any
  const [dataActivePT, setDataActivePT] = useState([]); // Changed from datapending to dataActivePT
  const [pageActivePT, setPageActivePT] = useState(0); // Changed from pagepending to pageActivePT
  const [rowsPerPageActivePT, setRowsPerPageActivePT] = useState(5); // Changed from rowsPerPagepending to rowsPerPageActivePT
  const [totalCountActivePT, setTotalCountActivePT] = useState(0); // Changed from totalCountpending to totalCountActivePT

  const [searchTextActivePT, setSearchTextActivePT] = useState(""); // Changed from searchTextpending to searchTextActivePT
  const [loadingUpcoming, setLoadingUpcoming] = useState(false);
  const [loadingPending, setLoadingPending] = useState(false);
  const [loadingActivePT, setLoadingActivePT] = useState(false);
  
  // Fetch data from the API based on page and rowsPerPage
  const fetchData = async () => {
    setLoadingUpcoming(true);
    try {
      const userData = JSON.parse(localStorage.getItem("userdata"));
      const { token, id: userId } = userData;
      const result = await getUpcomingExpire(
        token,
        rowsPerPage,
        searchText,
        page + 1
      ); // Passing token and pagination params
      setData(result.data); // Assuming `data` is where the list resides
      setTotalCount(result.total); // Assuming total count is returned for pagination
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingUpcoming(false);
    }
  };
  const fetchDatapending = async () => {
    setLoadingPending(true);
    try {
      const userData = JSON.parse(localStorage.getItem("userdata"));
      const { token, id: userId } = userData;
      const result = await getPendingDue(
        token,
        rowsPerPagepending,
        searchText,
        pagepending + 1
      ); // Passing token and pagination params
      setDatapending(result.data); // Assuming `data` is where the list resides
      setTotalCountpending(result.total); // Assuming total count is returned for pagination
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingPending(false);
    }
  };
  const fetchDatActivePT = async () => {
    setLoadingActivePT(true);
    try {
        const userData = JSON.parse(localStorage.getItem("userdata"));
        const { token, id: userId } = userData;

        // Use the updated state variables
        const result = await getActivePT(
            token,
            rowsPerPageActivePT, // Use rowsPerPageActivePT
            searchTextActivePT, // Use searchTextActivePT
            pageActivePT + 1 // Use pageActivePT
        ); 

        setDataActivePT(result.data); // Set the active PT data
        setTotalCountActivePT(result.total); // Set the total count for pagination
    } catch (error) {
        console.error("Error fetching data:", error);
    } finally {
      setLoadingActivePT(false);
    }
};


  // Fetch data when component mounts or when pagination/search changes
  useEffect(() => {
    fetchData();
   
  }, [page, rowsPerPage, ]);
  useEffect(() => {
  
   
    fetchDatActivePT();
  }, [pageActivePT,rowsPerPageActivePT]);
  useEffect(() => {
  
    fetchDatapending();
    
  }, [,pagepending,rowsPerPagepending]);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onPageChangedue = (event, newPage) => {
    setPagepending(newPage);
  };

  const onRowsPerPageChangedue = (event) => {
    setRowsPerPagepending(parseInt(event.target.value, 10));
    setPagepending(0); // Reset to the first page when changing rows per page
  };
  const onPageChangeActivePT = (event, newPage) => {
    setPageActivePT(newPage); // Use the new state variable for ActivePT
};

const onRowsPerPageChangeActivePT = (event) => {
    setRowsPerPageActivePT(parseInt(event.target.value, 10)); // Use the new state variable for ActivePT
    setPageActivePT(0); // Reset to the first page when changing rows per page
};


  return (
    <Box sx={{ display: "flex", height: "100vh", backgroundColor: "#EFEDF1" }}>
      <CssBaseline />
      <Box>
        <Header />
      </Box>

      <Box
    component="main"
    sx={{ flexGrow: 1, overflowY: 'auto', padding: '20px', backgroundColor: '#f9f9f9' }}
  >
   
    <div style={{ display: 'flex', flexGrow: 1, marginTop: '50px' }}>
      <div style={{ flex: 1, marginTop: '15px' }}>
        <Box>
       
        </Box>

        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 4 }}
          sx={{ gridAutoFlow: 'dense' }}
        >
          

          <Grid item xs={12}>
          <Button
    variant="contained"
    color="primary"
    onClick={handleBack}
    sx={{ boxShadow: 4, borderRadius: '10px', fontWeight:"bold", overflow: 'hidden',marginBottom:'4px' }}
    startIcon={<ArrowBackIcon />} // Add the back icon
  >
    Go Back
  </Button>
            <Card sx={{ boxShadow: 4, borderRadius: '10px', overflow: 'hidden' }}>
            <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 'bold',
              margin: '10px 0px',
              color: '#1976d2',
              textAlign: 'center',
            }}
          >
            Active PT
          </Typography>
              <TableContainer component={Paper} elevation={2}>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: '#1976d2',
                        color: '#fff',
                        '& th': { fontWeight: 'bold', fontSize: '16px' },
                      }}
                    >
                      <TableCell sx={{ color: '#fff' }}>Sl. No.</TableCell>
                      <TableCell sx={{ color: '#fff' }}>Image</TableCell>
                      <TableCell sx={{ color: '#fff' }}>Name</TableCell>
                      <TableCell sx={{ color: '#fff' }}>Mid</TableCell>
                      <TableCell sx={{ color: '#fff' }}>Phone No.</TableCell>
                      <TableCell sx={{ color: '#fff' }}>Email</TableCell>
                      <TableCell sx={{ color: '#fff' }}>Expire Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadingActivePT ? (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : (
                      dataActivePT
                        .slice(
                          pageActivePT * rowsPerPageActivePT,
                          pageActivePT * rowsPerPageActivePT + rowsPerPageActivePT
                        )
                        .map((row, index) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              '&:hover': { backgroundColor: '#e3f2fd' },
                            }}
                          >
                            <TableCell>{pageActivePT * rowsPerPageActivePT + index + 1}</TableCell>
                            <TableCell>
                              <img
                                src={row.image}
                                alt={row.name}
                                style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                              />
                            </TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.membership_number}</TableCell>
                            <TableCell>{row.phone}</TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{row.expire_date}</TableCell>
                          </TableRow>
                        ))
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={totalCountActivePT}
                  rowsPerPage={rowsPerPageActivePT}
                  page={pageActivePT}
                  onPageChange={onPageChangeActivePT}
                  onRowsPerPageChange={onRowsPerPageChangeActivePT}
                />
              </TableContainer>
            </Card>
          </Grid>

          {/* Pending Due Table */}
          <Grid item xs={12}>
            <Card sx={{ marginTop: '10px', boxShadow: 4, borderRadius: '10px' }}>
              <Typography
                sx={{
                  margin: '10px 5px',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  color: '#1976d2',
                  textAlign: 'center',
                }}
              >
                Pending Due
              </Typography>
              <TableContainer component={Paper} elevation={2}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#1976d2', color: '#fff' }}>
                      <TableCell sx={{ color: '#fff' }}>Sl. No.</TableCell>
                      <TableCell sx={{ color: '#fff' }}>Image</TableCell>
                      <TableCell sx={{ color: '#fff' }}>Name</TableCell>
                      <TableCell sx={{ color: '#fff' }}>Mid</TableCell>
                      <TableCell sx={{ color: '#fff' }}>Phone No.</TableCell>
                      <TableCell sx={{ color: '#fff' }}>Due Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadingPending ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : (
                      datapending
                        .slice(
                          pagepending * rowsPerPagepending,
                          pagepending * rowsPerPagepending + rowsPerPagepending
                        )
                        .map((row, index) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              '&:hover': { backgroundColor: '#e3f2fd' },
                            }}
                          >
                            <TableCell>{pagepending * rowsPerPagepending + index + 1}</TableCell>
                            <TableCell>
                              <img
                                src={row.image}
                                alt={row.name}
                                style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                              />
                            </TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.membership_number}</TableCell>
                            <TableCell>{row.phone}</TableCell>
                            <TableCell>{row.total_due}</TableCell>
                          </TableRow>
                        ))
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={totalCountpending}
                  rowsPerPage={rowsPerPagepending}
                  page={pagepending}
                  onPageChange={onPageChangedue}
                  onRowsPerPageChange={onRowsPerPageChangedue}
                />
              </TableContainer>
            </Card>
          </Grid>

          {/* Upcoming Membership Expiry Table */}
          <Grid item xs={12}>
            <Card sx={{ marginTop: '10px', boxShadow: 4, borderRadius: '10px' }}>
              <Typography
                sx={{
                  margin: '10px 5px',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  color: '#1976d2',
                  textAlign: 'center',
                }}
              >
                Upcoming Membership Expiry
              </Typography>
              <TableContainer component={Paper} elevation={2}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#1976d2', color: '#fff' }}>
                      <TableCell sx={{ color: '#fff' }}>Sl. No.</TableCell>
                      <TableCell sx={{ color: '#fff' }}>Image</TableCell>
                      <TableCell sx={{ color: '#fff' }}>Name</TableCell>
                      <TableCell sx={{ color: '#fff' }}>Mid</TableCell>
                      <TableCell sx={{ color: '#fff' }}>Phone No.</TableCell>
                      <TableCell sx={{ color: '#fff' }}>Expire Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadingUpcoming ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : data.length > 0 ? (
                      data.map((row, index) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            '&:hover': { backgroundColor: '#e3f2fd' },
                          }}
                        >
                          <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                          <TableCell>
                            <img
                              src={row.image}
                              alt={row.name}
                              style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                            />
                          </TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.membership_number}</TableCell>
                          <TableCell>{row.phone}</TableCell>
                          <TableCell>{row.expire_date}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <Typography>No upcoming expiries.</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
    <Button
      variant="contained"
      color="primary"
      onClick={handleBack}
      sx={{ position: 'absolute', top: '20px', right: '20px' }}
    >
      Back
    </Button>
  </Box>

    </Box>
  );
};

export default Dashboard;
