import * as React from "react";
import { useState, useEffect } from "react";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PropTypes from "prop-types";
import { Button, Popover,TextField } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import { Drawer, Tooltip, Avatar } from "@mui/material";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import IconButton from "@mui/material/IconButton";
import GroupsIcon from "@mui/icons-material/Groups";
import List from "@mui/material/List";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { styled } from "@mui/material/styles";
import { usePath } from "./PathContext";
import { Link, Navigate } from "react-router-dom";
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import SecurityIcon from "@mui/icons-material/Security";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import QuizIcon from "@mui/icons-material/Quiz";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";
import "./Sidebar.css";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useNavigate } from "react-router-dom";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import { useLocation } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import EngineeringIcon from "@mui/icons-material/Engineering";
const drawerWidth = 240;
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Playfair Display, serif',
  fontWeight: 700,
  fontSize: '2rem', // Adjust size as needed
  color: '#4A4A4A', // Default color if needed
 
  letterSpacing: '0.5px', // Optional
  display: 'flex',
  flexWrap: 'wrap', // Ensure wrapping of text if needed
}));
const PremiumPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '12px',
    padding: theme.spacing(2),
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
    backgroundColor: '#fff',
    minWidth: 220,
    color: '#333',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  background: 'linear-gradient(135deg, #FF6F61, #DE4313)',
  color: '#fff',
  fontWeight: 'bold',
  textTransform: 'none',
  '&:hover': {
    background: 'linear-gradient(135deg, #DE4313, #FF6F61)',
  },
}));
const StyledPremiumTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Playfair Display, serif', // Premium serif font for elegance
  fontWeight: 700, // Keep the bold font-weight for presence
  fontSize: '2rem', // Keep the current size for premium feel
  color: '#1A1A1A', // Premium dark grey for a sophisticated look
  letterSpacing: '0.5px', // Slight spacing for elegance
  display: 'inline-block',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-5px', // Keep the underline close to the text
    left: '0',
    height: '2px',
    width: '100%',
    background: 'linear-gradient(90deg, #DE4313, #FF6F61)', // Premium gradient effect
    opacity: 0.8, // Subtle opacity for a refined feel
  },
}));

const AbstractText = ({ text }) => {
  return (
    <StyledPremiumTypography variant="h6" component="div">
      {text}
    </StyledPremiumTypography>
  );
};



function ResponsiveDrawer(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentPath, setCurrentPath } = usePath();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const pathName = useLocation().pathname;
  const [isHover, setIsHover] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isActive = (route) => {
    return pathName.substring(1) === route;
  };
  // const handleClose = () => {
  //   localStorage.removeItem("userdata");
  //   // setSnackbaropen(true);
  //   navigate("/", { replace: true });
  //   setOpen(false);
  // };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const StyledList = styled(List)({
    "&& .Mui-selected, && .Mui-selected:hover": {
      backgroundColor: "#0F4DFF",
      "&, & .MuiListItemIcon-root": {
        color: "#4361EE",
      },
    },
    "& .MuiListItemButton-root:hover": {
      backgroundColor: "#30344A",
      "&, & .MuiListItemIcon-root": {
        color: "#4361EE",
      },
    },
  });
  const handleCloseCancle = () => {
    localStorage.removeItem("userdata")
    setSnackbaropen(true);
     navigate("/", { replace: true });
    setOpen(false);
  };

  const [user, setUser] = useState(null);

  useEffect(() => {
    // Retrieve user data from local storage
    const storedUserData = localStorage.getItem('userdata');
    console.log("storedUserData",storedUserData)
    if (storedUserData) {
      setUser(JSON.parse(storedUserData));
    }
  }, []);

  // Extract first letter of the user's name and user color
  const userInitial = user?.name?.charAt(0).toUpperCase() || 'M'; // Default to 'M' if user data is not available
  const userColor = user?.user_color || '#000000'; // Default to black if color is not available

  const menuItems = [
    {
      name: "Dashboard",
      icon: "images/frame16.svg",
      activeIcon: "images/frame10.svg",
      route: "dashboard",
    },
    {
      name: "Members",
      icon: "images/frame21.svg",
      activeIcon: "images/frame18.svg",
      route: "memberlist",
    },
    {
      name: "Payment",
      icon: "images/frame21.svg",
      activeIcon: "images/frame18.svg",
      route: "payment",
    },
    {
      name: "Users",
      icon: "images/frame11.svg",
      activeIcon: "images/frame1.svg",
      route: "userlist",
    },
  ];
  // Handle opening and closing of Popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
        localStorage.removeItem("userdata");
    // setSnackbaropen(true);
    navigate("/", { replace: true });
  };
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleLogout = () => {
    // Clear user data and perform logout actions
    localStorage.removeItem('userdata');
    // Redirect or other logout logic here
    handleClose();
  };

  const handleCloseout = () => {
    
    setAnchorEl(null);
   
  };

  const openn = Boolean(anchorEl);
  const id = openn ? 'account-menu' : undefined;
  const drawer = (
    <div
      className="mai_div_menu"
      style={{ backgroundColor: "#1C2534",minHeight:"100vh"}}
    >
      <div
        className="frameWrapper_menulist"
        style={{ display: "flex", alignItems: "center" }}
      >
        {/* <div className="frameContainer_menulist" > */}
        {/* <div className="frameGroup_menulist" style={{ paddingLeft: "26px" }}> */}
        <a href="/#/">
          <img
            
            alt=""
            src="images/rectangle-9@2x.png"
            // width={400} height={100}
            style={{ width: "100px", height: "100px", }}
          />
        </a>
        {/* <b className="crypto_menulist">Crowd Wisdom</b> */}
        {/* </div> */}
        {/* </div> */}
      </div>
      <StyledList sx={{ backgroundColor: "#1C2534", }}>
        <List
          sx={{ margin: 0, paddingLeft: "26px" }}
          component="nav"
          aria-label="secondary mailbox folder"
        >
          <div>
            <ListItem
              disablePadding
              className="list__sidebar__bar__privacy"
              component={Link}
              to="/dashboard"
            >
              <ListItemButton
                className={`${isActive.privacyPolicy} ${
                  location.pathname === "/dashboard" ? "activeMenu" : ""
                }`}
                style={{
                  borderRadius: "8px 0px 0px 8px",
                  marginBottom: "3px",
                  borderRight: isActive("List Due Users")
                    ? "1px solid blue"
                    : "",
                  padding: "10px",
                  color: "white",
                }}
              >
                <ListItemIcon
                  sx={{
                    color: location.pathname === "/dashboard" ? "blue" : "grey",
                  }}
                >
                  <DashboardIcon />
                </ListItemIcon>
                <span style={{ fontSize: "14px" }}>Dashboard</span>
                {/* <ListItemText primary="Privacy Policy" /> */}
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              className="list__sidebar__bar__privacy"
              component={Link}
              to="/Followup-visitors"
            >
              <ListItemButton
                className={`${isActive.privacyPolicy} ${
                  location.pathname === "/Followup-visitors" ? "activeMenu" : ""
                }`}
                style={{
                  borderRadius: "8px 0px 0px 8px",
                  marginBottom: "3px",
                  borderRight: isActive("Followup-visitors")
                    ? "1px solid blue"
                    : "",
                  padding: "10px",
                  color: "white",
                }}
              >
                <ListItemIcon
                  sx={{
                    color: location.pathname === "/Followup-visitors" ? "blue" : "grey",
                  }}
                >
                  <BookmarksIcon />
                </ListItemIcon>
                <span style={{ fontSize: "14px" }}>Follow Up</span>
                {/* <ListItemText primary="Privacy Policy" /> */}
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              className="list__sidebar__bar__about"
              component={Link}
              to="/memberlist"
            >
              <ListItemButton
                className={`${isActive.termofuse} ${
                  location.pathname === "/memberlist" ||
                  location.pathname.startsWith("/payment") ||
                  location.pathname.startsWith("/memberdetails") ||
                  location.pathname.startsWith("/newmember")
                    ? "activeMenu"
                    : ""
                }`}
                style={{
                  borderRadius: "8px 0px 0px 8px",
                  marginBottom: "3px",
                  borderRight: isActive("Members") ? "1px solid blue" : "",
                  padding: "10px",
                  color: "white",
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      location.pathname === "/memberlist" ||
                      location.pathname.startsWith("/payment") ||
                      location.pathname.startsWith("/memberdetails") ||
                      location.pathname.startsWith("/newmember")
                        ? "blue"
                        : "grey",
                  }}
                >
                  <GroupsIcon />
                </ListItemIcon>
                <span style={{ fontSize: "14px" }}>Members</span>
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              className="list__sidebar__bar__about"
              component={Link}
              to="/managepackage"
            >
              <ListItemButton
                className={`${isActive.termofuse} ${
                  location.pathname === "/managepackage" ? "activeMenu" : ""
                }`}
                style={{
                  borderRadius: "8px 0px 0px 8px",
                  marginBottom: "3px",
                  borderRight: isActive("Manage Package")
                    ? "1px solid blue"
                    : "",
                  padding: "10px",
                  color: "white",
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      location.pathname === "/managepackage" ? "blue" : "grey",
                  }}
                >
                  <WorkspacesIcon />
                </ListItemIcon>
                {/* <ListItemText primary="Terms of Use" /> */}
                <span style={{ fontSize: "14px" }}>Manage Package</span>
              </ListItemButton>
            </ListItem>

            <ListItem
              disablePadding
              className="list__sidebar__bar__about"
              component={Link}
              to="/ParentOfferPackage"
            >
              <ListItemButton
                className={`${isActive.termofuse} ${
                  location.pathname === "/ParentOfferPackage"
                    ? "activeMenu"
                    : ""
                }`}
                style={{
                  borderRadius: "8px 0px 0px 8px",
                  marginBottom: "3px",
                  borderRight: isActive("Manage Package")
                    ? "1px solid blue"
                    : "",
                  padding: "12px",
                  color: "white",
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      location.pathname === "/ParentOfferPackage"
                        ? "blue"
                        : "grey",
                  }}
                >
                  <ListAltIcon />
                </ListItemIcon>
                {/* <ListItemText primary="Terms of Use" /> */}
                <span style={{ fontSize: "14px" }}>Offer Package</span>
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              className="list__sidebar__bar__privacy"
              component={Link}
              to="/listdueusers"
            >
              <ListItemButton
                className={`${isActive.privacyPolicy} ${
                  location.pathname === "/listdueusers" ? "activeMenu" : ""
                }`}
                style={{
                  borderRadius: "8px 0px 0px 8px",
                  marginBottom: "3px",
                  borderRight: isActive("List Due Users")
                    ? "1px solid blue"
                    : "",
                  padding: "12px",
                  color: "white",
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      location.pathname === "/listdueusers" ? "blue" : "grey",
                  }}
                >
                  <ReduceCapacityIcon />
                </ListItemIcon>
                <span style={{ fontSize: "14px" }}>List Due Users</span>
                {/* <ListItemText primary="Privacy Policy" /> */}
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              className="list__sidebar__bar__about"
              component={Link}
              to="/balance-sheet"
            >
              <ListItemButton
                className={`${isActive.termofuse} ${
                  location.pathname === "/balance-sheet" ? "activeMenu" : ""
                }`}
                style={{
                  borderRadius: "8px 0px 0px 8px",
                  marginBottom: "3px",
                  borderRight: isActive("Balance Sheet")
                    ? "1px solid blue"
                    : "",
                  padding: "12px",
                  color: "white",
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      location.pathname === "/balance-sheet" ? "blue" : "grey",
                  }}
                >
                  <AssignmentIcon />
                </ListItemIcon>
                {/* <ListItemText primary="Terms of Use" /> */}
                <span style={{ fontSize: "14px" }}>Balance Sheet</span>
              </ListItemButton>
            </ListItem>
          </div>
        </List>
      </StyledList>

      <Divider />
      <StyledList sx={{ backgroundColor: "#1C2534" }}>
        <List
          sx={{ margin: 0, paddingLeft: "26px" }}
          component="nav"
          aria-label="secondary mailbox folder"
        >
          <div>
            <ListItem
              disablePadding
              className="list__sidebar__bar__about"
              component={Link}
              to="/employeesdetail"
            >
              <ListItemButton
                className={`${isActive.termofuse} ${
                  location.pathname === "/employeesdetail" ||
                  location.pathname.startsWith("/addemployees")
                    ? "activeMenu"
                    : ""
                }`}
                style={{
                  borderRadius: "8px 0px 0px 8px",
                  marginBottom: "3px",
                  borderRight: isActive("Employees") ? "1px solid blue" : "",
                  padding: "10px",
                  color: "white",
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      location.pathname === "/employeesdetail" ||
                      location.pathname.startsWith("/addemployees")
                        ? "blue"
                        : "grey",
                  }}
                >
                  <EngineeringIcon />
                </ListItemIcon>
                {/* <ListItemText primary="Terms of Use" /> */}
                <span style={{ fontSize: "14px" }}>Employees</span>
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              className="list__sidebar__bar__about"
              component={Link}
              to="/userlist"
            >
              <ListItemButton
                className={`${isActive.termofuse} ${
                  location.pathname === "/userlist" ||
                  location.pathname.startsWith("/adduser")
                    ? "activeMenu"
                    : ""
                }`}
                style={{
                  borderRadius: "8px 0px 0px 8px",
                  marginBottom: "3px",
                  borderRight: isActive("User Details") ? "1px solid blue" : "",
                  padding: "10px",
                  color: "white",
                }}
              >
                <ListItemIcon
                  sx={{
                    color:
                      location.pathname === "/userlist" ||
                      location.pathname.startsWith("/adduser")
                        ? "blue"
                        : "grey",
                  }}
                >
                  <AccountCircleIcon />
                </ListItemIcon>
                {/* <ListItemText primary="Terms of Use" /> */}
                <span style={{ fontSize: "14px" }}>User Details</span>
              </ListItemButton>
            </ListItem>
          </div>
        </List>
      </StyledList>
      <div className="logoutWrapper_menulist">
        <Button
          sx={{ color: "#fff" }}
          aria-label="add"
          onClick={() => handleClickOpen()}
          className="logoutBtn_menulist"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            backgroundColor: isHover ? "#bb2142" : "#ce2d4f",
            width: "100%",
            height: "45px",
            justifyContent: "start",
            padding: "12px",
            borderRadius: "8px",
          }}
        >
          <img className="frameIcon_menulist" alt="" src="images/frame23.svg" />{" "}
          Log out
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            Logout
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure to logout?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseCancle}>
              Cancel
            </Button>
            <Button onClick={handleClose}>Logout</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;
    
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "white",
          color: "black",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <AbstractText text="Maa Tara Tarini Multi Gym" />

          </Box>
          <Box sx={{ display: "flex" }}>
            <Box>
              
            </Box>
            <Box>
      <Tooltip title="Account settings">
        <IconButton
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <Avatar sx={{ width: 32, height: 32, bgcolor: userColor }}>
            {userInitial}
          </Avatar>
        </IconButton>
      </Tooltip>
      <PremiumPopover
        id={id}
        open={openn}
        anchorEl={anchorEl}
        onClose={handleCloseout}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', color: '#333' }}>
            {user?.name}
          </Typography>
          <Divider sx={{ my: 1, bgcolor: '#ddd' }} />
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            {user?.email}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {user?.phone}
          </Typography>
          <StyledButton variant="contained" fullWidth onClick={handleLogout}>
            Logout
          </StyledButton>
        </Box>
      </PremiumPopover>
    </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
