import "./ListUser.css";
import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Header from '../Includes/Header';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import Tab1 from './Tab1';
import Tab2 from './Tab2';
import Tab3 from './Tab3';
import MembershipExpired from './MembershipExpired';
import Ptexpired from './Ptexpired';
import Loader from './Loader'; // Import the Loader component
import { useNavigate } from "react-router-dom";
const drawerWidth = 292;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function ListDueUsers() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false); // Add a state for loader

  const handleChangetab = (event, newValue) => {
    setLoading(true); // Show loader
    setValue(newValue);
  };
  React.useEffect(() => {
    const storedData = localStorage.getItem("userdata");
    if (!storedData) {
      // User is not logged in, no need to redirect
      navigate('/');
    }
    
  }, []);
  React.useEffect(() => {
    // Simulate a delay to show loader when tab changes
    if (loading) {
      const timer = setTimeout(() => {
        setLoading(false); // Hide loader after delay
      }, 500); // Adjust the delay as needed

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [loading]);

  const tabStyle = (isActive) => ({
    textDecoration: 'none',
    flexGrow: 1,
    textTransform: 'none',
    fontWeight: 'bold',
    marginRight: '10px',
    backgroundColor: isActive ? '#D8E2FF' : 'inherit',
    borderRadius: "10px 10px 0px 0px",
    borderBottom: isActive ? "2px solid #005AC1" : 'none',
    color: isActive ? "#005AC1" : 'inherit',
  });

  return (
    <Box sx={{ display: 'flex', height: '100vh', backgroundColor: "#EFEDF1" }}>
      <CssBaseline />
      <Box>
        <Header />
      </Box>

      <Box component="main" sx={{ flexGrow: 1, overflowY: 'auto', padding: '20px' }}>
        <div style={{ display: 'flex', flexGrow: 1, marginTop: '50px' }}>
          <div style={{ flex: 1, marginTop: '15px' }}>
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ borderBottom: 1, marginLeft: '25px', borderColor: 'divider' }}>
                
                <Tabs
                  value={value}
                  onChange={handleChangetab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab sx={tabStyle(value === 0)} label="Due" {...a11yProps(0)} />
                  <Tab sx={tabStyle(value === 1)} label="Payment Due" {...a11yProps(1)} />
                  <Tab sx={tabStyle(value === 2)} label="Pt Package Due" {...a11yProps(2)} />
                  <Tab sx={tabStyle(value === 3)} label="Membership Expired" {...a11yProps(3)} />
                  <Tab sx={tabStyle(value === 4)} label="Personal Trainer Package Expire" {...a11yProps(4)} />
                </Tabs>
              </Box>
            </Box>

            <TabPanel value={value} index={0}>
              <Tab1 />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Tab2 />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Tab3 />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <MembershipExpired />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Ptexpired />
            </TabPanel>

            {loading && <Loader />} {/* Show loader when loading */}
          </div>
        </div>
      </Box>
    </Box>
  );
}

export default ListDueUsers;
