import "./BalanceSheet.css";
import * as React from "react";
import Tooltip from '@mui/material/Tooltip';
import Table from "@mui/material/Table";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs';
import Drawer from "@mui/material/Drawer";
import { ButtonGroup } from "@mui/material";
import DateFilter from "./DateFilter.jsx";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Header from '../Includes/Header';
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useEffect, useRef } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CancelIcon from '@mui/icons-material/Cancel';
import { Card, Button, Grid, TextField, FormControl,Snackbar,Alert, InputLabel, Select, MenuItem, FormHelperText, Paper } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContentText } from '@mui/material';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Menu from '@mui/material/Menu';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from "@mui/material/CircularProgress";
import json2mq from "json2mq";
import { Link } from 'react-router-dom';

import {
    UpdateFollowUP,
    createFollowup,
    retrieveFollwupVisitor,
    deleteFollowup,
    AutocompleteFollowup,
    StatusFollowup,
  } from "../Apihelper/FollowupApi";




import { useNavigate } from "react-router-dom";

const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 600,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide the card container on small screens (mobile view)
    },
  },
  card: {
    width: "100%",
    margin: "8px",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px", // Adjust the card width as needed
    },
  },
}));
const drawerWidth = 292;

function FollowUP(props) {
  const navigate = useNavigate();
  const [deleteConfirmationId, setDeleteConfirmationId] = useState(null);
  const { window1 } = props;
  const [field, setField] = useState("");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const dateRef = useRef();
  const [age, setAge] = React.useState('');
  const [hasMore, setHasMore] = useState(true);
  const [status, setstatus] = useState('');
  const [options, setOptions] = useState([]);
  const [fetchdatarow, setetchdatarow] = useState([]); 
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openToast, setOpenToast] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Default to 'success'
  const [index, setIndex] = useState(0);
  const [datelist, setDatelist] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [todate, settodate] = useState("");
  const [field1, setField1] = useState("");
  const [field2, setField2] = useState("");
  const [field3, setField3] = React.useState(null); // Initialize as null or dayjs()
  const [field4, setField4] = React.useState(null); // Initialize as null or dayjs()
  
  const [editItem, setEditItem] = useState({});
const [modalMode, setModalMode] = useState("");
const [formSubmitted, setFormSubmitted] = useState(false);
const [addmodalopen, setaddmodalopen] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const handleaddOpen = () => {
    setModalMode("add");
    setaddmodalopen(true);
  };
  const handleaddClose = () => {
    setField1("");
    setField2("");
    setField3("");
    setField4("");
    setFormSubmitted(false);
    setaddmodalopen(false);
  };
  const handleCancelDelete = () => {
    setOpenDeleteConfirmation(false);
  };
  const handleConfirmDelete = async (id) => {
    // setSnackbarMessage('package deleted successfully');
    // setSnackbarSeverity('error');
    // setOpenDeleteConfirmation(false);
    // setOpenToast(true); // Show toast message

    if (deleteConfirmationId !== null) {
      try {
        const userData = localStorage.getItem("userdata");
        const userDataObj = JSON.parse(userData);
        const userToken = userDataObj.token;
        const userId = userDataObj.id;

        const res = await deleteFollowup(
          userToken,
          userId,
          deleteConfirmationId
        );

        if (res.code == "200") {
          handleSnackbarOpen(res.message, "success");
          setOpenDeleteConfirmation(false);
          fetchData();
        } else {
          handleSnackbarOpen(res.message, "error");
          //  console.error(error); // Handle error
        }
      } catch (error) {
        console.error(error);
        // Handle errors
      }
    }
  };

  const handleSubmit = async () => {
    setFormSubmitted(true)
    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    if (!field1 || !field2 || !field3 || !field4) {
     
      return; // Stop submission if validation fails
    }
    if (modalMode === "add") {
      try {
        const res = await createFollowup(
          userToken,
          field1,
          field2,
          field3,
          field4
        );
        if (res.code == "200") {
          setField1("");
          setField2("");
          setField3("");
          setField4("");

          handleSnackbarOpen(res.message, "success");
          fetchData();
        } else {
          handleSnackbarOpen(res.message, "error");
          //   console.error(error); // Handle error
        }
      } catch (error) {
        console.error(error); // Handle error
      }
    } else if (modalMode === "edit") {
      try {
        if (editItem) {
          const { id, name, phone, visit_date, followup_date } =
            editItem;

          const res = await UpdateFollowUP(
            id,
            userToken,
            field1,
            field2,
            field3,
            field4
          );
          if (res.code == "200") {
            setField1("");
            setField2("");
            setField3("");
            setField4("");

            handleSnackbarOpen(res.message, "success");
            fetchData();
          } else {
            handleSnackbarOpen(res.message, "error");
            //   console.error(error); // Handle error
          }
        }
      } catch (error) {
        console.error(error); // Handle error
      }
    }

    // Close the modal after submission
    handleaddClose();
  };

  const handleConfirm = async() => {
    // setIsActive(!isActive);
    // setOpenConfirmation(false);

    try {
        const userData = localStorage.getItem("userdata");
        const userDataObj = JSON.parse(userData);
        const userToken = userDataObj.token;
        const userId = userDataObj.id; 
     
      
        const res = await StatusFollowup(userToken,userId,status, deleteConfirmationId);

        if (res.code == "200") {
            handleSnackbarOpen(res.message, 'success');
            setIsActive(!isActive);
            setOpenConfirmation(false);
          //  setOpenDeleteConfirmation(false);
            fetchData();
        } else {
            handleSnackbarOpen(res.message, 'error');
            //  console.error(error); // Handle error
        }
    } catch (error) {
        console.error(error);
        // Handle errors
    }
  };
  const handleCancel = () => {
    setOpenConfirmation(false);
  };
 
 


 
 
  const open = Boolean(anchorEl);

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenToast(true);
  };
  const handleToastClose = () => {
    setOpenToast(false);
  };

  const fetchData = async (value = '') => {
    setLoading(true);
    setHasMore(true);
    try {
        const userData = localStorage.getItem("userdata");
        const userDataObj = JSON.parse(userData);
        const userToken = userDataObj.token;
        const userId = userDataObj.id; // Assuming you have a userId in userdata
        const searchText = value; // Example search text
        const limit = 10; // Example limit
        const index = 0; // Example index
        const res = await retrieveFollwupVisitor(userToken, userId, searchText, limit, index, datelist,
          fromDate,
          "",);

        if (res.code == "200") {
            setetchdatarow(res.data)
            console.log(res.data);
            setLoading(false);
        } else {
            handleSnackbarOpen(res.message, 'error');
            setLoading(false);
            // console.error(error); // Handle error
        }
    } catch (error) {
        console.error(error);
        // Handle errors
    }
};

const handleApplydatecustom = async ({ fromDateval, toDateval,value = '' }) => {
  settodate(toDateval);
  setFromDate(fromDateval);
  setLoading(true);
  try {
    const storedUserData = localStorage.getItem("userdata");
    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const userId = userDataObj.id; // Assuming you have a userId in userdata
    const searchText = value; // Example search text
    const limit = 10; // Example limit
    const index = 0; // Example index
    setIndex(0);
    const res = await retrieveFollwupVisitor(
      userToken, 
      userId, 
      searchText, 
      limit,
       index,
      datelist,
      fromDateval,
      toDateval,
  
    );
    if (res.code == "200") {
      setetchdatarow(res.data)
      console.log(res.data);
      setLoading(false);
      if (res.data.length >= 0 && res.data.length <10) {
        setHasMore(false)
      }
      else {
        setHasMore(true);
        setLoading(false);
      }
    }
  } catch (error) {
    console.error("Error:", error);
  }
  // Handle the selected value in the parent page
};
const handleApplydate = async (selectedValue,value = '') => {
  setDatelist(selectedValue);
  setLoading(true);
  try {
    const storedUserData = localStorage.getItem("userdata");
    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const userId = userDataObj.id; // Assuming you have a userId in userdata
    const searchText = value; // Example search text
    const limit = 10; // Example limit
    const index = 0; // Example index
    const data = await retrieveFollwupVisitor(
      userToken, 
      userId, 
      searchText, 
      limit,
       index,
       selectedValue,
      "",
      "",
    );
    if (data.code == "200") {
      setLoading(false);
      setetchdatarow(() => [...data.data]);
    
    
      if ( data.data.length >= 0 && data.data.length <10) {
        setHasMore(false)
      }
      else {
        setHasMore(true);
        setLoading(false);
      }
    }
  } catch (error) {
    console.error("Error:", error);
  }
  // Handle the selected value in the parent page
};
  useEffect(() => {
   
    fetchData();
}, []);

const handleLoadMore = async () => {
  // Calculate the next index based on the number of items loaded so far
  const nextIndex = fetchdatarow.length

  const userData = localStorage.getItem("userdata");
  const userDataObj = JSON.parse(userData);

  try {
    const userToken = userDataObj.token;
    const userId = userDataObj.id;
    const searchText = ''
    const limit = 10;

    const res = await retrieveFollwupVisitor(userToken, userId, searchText, limit, nextIndex,datelist,
      fromDate,
      "",);

    if (res.code == "200") {
      // Append the newly loaded data to the existing data
      setetchdatarow((prevResults) => [...prevResults, ...res.data]);

      // Check if there are more results available
      if (res.data.length >= 0 && res.data.length < 10) {
        setHasMore(false);
      } else {
        setHasMore(true);
        setLoading(false);
      }
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

const handleSearchChange = (event, value) => {
  const searchText = value || ''; // If value is undefined or empty, set searchText to empty string
  // setSearchText(searchText);
  // Call fetchData function here with the updated searchText
  fetchData(searchText);
};


const handleInputChange = async (value) => {
  if (value.length >= 3) {
  try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id; // Assuming you have a userId in userdata
      const searchText = value; // Example search text
    
      const res = await AutocompleteFollowup(userToken, userId, searchText);

      if (res.code == "200") {
          const names = res.data.map(item => item.search_text);
          setOptions(names);
      } else {
          handleSnackbarOpen(res.message, 'error');
          //  console.error(error); // Handle error
      }
  } catch (error) {
      console.error(error);
      // Handle errors
  }
}
};


React.useEffect(() => {
  const storedData = localStorage.getItem("userdata");
  if (!storedData) {
    // User is not logged in, no need to redirect
    navigate('/');
  }
  
}, []);


//  modalMode
const handleEditModalOpen = (
  id,
  name,
  phone,
  visit_date,
  followup_date
) => {
  setModalMode("edit");
  setEditItem({
    id: id,
    name: name,
    phone: phone,
    visit_date: visit_date,
    followup_date: followup_date,
  });
  // console.log(admissionValue);
  setField(id);
  setField1(name || "");
  setField2(phone || "");
  console.log("visit_date:", visit_date);
  console.log("Parsed visit_date with dayjs:", dayjs(visit_date).format());

  setField3(visit_date ? dayjs(visit_date, "DD-MM-YYYY") : null); 

  setField4(followup_date ? dayjs(followup_date, "DD-MM-YYYY") : null); 
  setaddmodalopen(true); // Open the edit modal
};
const handleDelete = (id) => {
    handleClose();
    setDeleteConfirmationId(id);
    setOpenDeleteConfirmation(true, id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleToggle = (id, status) => {
    setOpenConfirmation(true);
    const newStatus = status === 1 ? 0 : 1;
    setstatus(newStatus);
    setDeleteConfirmationId(id);
    setIsActive(newStatus === 0);
  };

  
  return (
    <Box sx={{ display: 'flex', height: '100vh', backgroundColor: "#EFEDF1" }}>
      <CssBaseline />
      <Box  >
        <Header />
      </Box>
      <Box component="main" sx={{ flexGrow: 1, overflowY: 'auto', padding: '20px', }}>
        <div style={{ display: 'flex', flexGrow: 1, marginTop: '50px' }}>
          <div style={{ flex: 1, marginTop: '15px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box >
               
              </Box>
              <Box sx={{ marginLeft: "auto" }}>
          <Button
            variant="contained"
            startIcon={<ControlPointIcon />}
            onClick={handleaddOpen}
            sx={{
              textTransform: "none",
              whiteSpace: "nowrap",
              fontWeight: 700,
            }}
          >
            Add New
          </Button>
        </Box>

            </Box>


            <Box sx={{ display: 'flex' }}>
              <Box>
                <Autocomplete
                  style={{ height: 50, }}
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  options={options}
                  onInputChange={(event, value) => handleInputChange(value)} // Pass searchText to handleInputChange
                  onChange={(event, value) => handleSearchChange(event, value)}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      sx={{ width: 300 }}
                      {...params}
                      label="Search input"
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />

                  )}
                  sx={{
                    '& .MuiInputBase-root': {


                      alignItems: 'center',
                      height: '50px',

                    },
                  }}
                />
              </Box>
            
              <Box>
                <FormControl >
                  <DateFilter ref={dateRef}
                   onApplycustomdate={handleApplydatecustom}
                   onApply={handleApplydate}
                  />
                </FormControl>
              </Box>
            </Box>


            <TableContainer
      component={Paper}
      sx={{
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        borderRadius: "10px",
        overflow: "hidden",
        margin: "20px 0",
      }}
    >
      {loading ? ( // Display spinner if loading is true
        <Box sx={{ textAlign: "center", padding: "50px 0" }}>
          <CircularProgress />
        </Box>
      ) : ( // Display table if loading is false
        <Table  sx={{ minWidth: 650 }}aria-label="premium balance sheet table" size="small" >
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: '#2f80ed',
              }}
            >
             
              <TableCell
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
                
                }}
              >
             Name
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
                  padding: "16px",
                }}
              >
              Phone
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
                 
                }}
              >
           Followup Date
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
               
                }}
              >
             
Visit Date

              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
                 
                }}
              >
                Status
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
                 
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fetchdatarow.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{
                  "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                  "&:hover": {
                    backgroundColor: "#e3f2fd",
                    cursor: "pointer",
                  },
                }}
              >
              
                <TableCell align="left"
                  sx={{
                    padding: "10px",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
             
    {row.name}

                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    padding: "10px",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  {row.phone}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    padding: "10px",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  {row.followup_date}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    padding: "10px",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  }}
                >
                  {row.visit_date}
                </TableCell>
                <TableCell align="left">
                <Button
                  variant="contained"
                  sx={{
                    fontWeight: 700,
                    textTransform: "none",
                    borderRadius: "20px",
                    backgroundColor: row.status === 1 ? "#43a047" : "#e53935", // Green for active, red for inactive
                    color: "#ffffff",
                    "&:hover": {
                      backgroundColor: row.status === 1 ? "#388e3c" : "#d32f2f",
                    },
                  }}
                  onClick={() => handleToggle(row.id, row.status)}
                >
                  {row.status === 1 ? "Followed" : "Pending"}
                </Button>
              </TableCell>
                <TableCell>
                <>
                  <IconButton
                    color="primary"
                    aria-label="edit"
                    onClick={() =>
                      handleEditModalOpen(
                        row.id,
                        row.name,
                        row.phone,
                        row.visit_date,
                        row.followup_date
                      )
                    }
                    sx={{ "&:hover": { color: "#c2185b" } }} // Hover effect for edit button (dark pink)
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="error"
                    aria-label="delete"
                    onClick={() => handleDelete(row.id)}
                    sx={{ "&:hover": { color: "#b71c1c" } }} // Hover effect for delete button
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
             <Button
  variant="contained"
  color="primary"
  onClick={handleLoadMore}
 
  disabled={!hasMore || (fetchdatarow.length < 10 && fetchdatarow.length !== 0)}
  sx={{ fontWeight: 'bold', textTransform: 'none' }}
>
  {fetchdatarow.length === 0 || fetchdatarow.length < 10 ? 'No More Records' : 'Load More'}
</Button>


      </div>
          </div>

        </div>

      </Box>
      <Dialog open={addmodalopen} maxWidth="xs" onClose={handleaddClose}>
  <DialogTitle>
    {modalMode === "add" ? "Add Visitors" : "Edit Visitors"}
  </DialogTitle>
  <DialogContent>
    <TextField
      autoFocus
      margin="dense"
      label="Name"
      fullWidth
      type="text"
      value={field1}
      error={formSubmitted && !field1}
      helperText={formSubmitted && !field1 ? "Name is required" : ""}
      onChange={(e) => setField1(e.target.value)}
    />
    <TextField
      margin="dense"
      label="Phone"
      fullWidth
      type="number"
      error={formSubmitted && !field2}
      helperText={formSubmitted && !field2 ? "Phone is required" : ""}
      value={field2}
      inputProps={{ min: 0 }}
      onChange={(e) => setField2(Math.max(0, e.target.value))}
    />

<LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box display="flex" justifyContent="space-between" gap={2} mt={2}>
            <DatePicker
              value={field3 ? dayjs(field3) : null}
              onChange={(date) => setField3(date)}
              label="Visiting Date"
              format="DD/MM/YYYY"
              clearable
              fullWidth
            />
            <DatePicker
              value={field4 ? dayjs(field4) : null}
              onChange={(date) => setField4(date)}
              label="Follow up Date"
              format="DD/MM/YYYY"
              clearable
              fullWidth
            />
          </Box>
        </LocalizationProvider>
  </DialogContent>
  <DialogActions>
    <Button
      sx={{ fontWeight: "bold", textTransform: "none" }}
      startIcon={<CancelIcon />}
      onClick={handleaddClose}
    >
      Cancel
    </Button>
    <Button
      sx={{ fontWeight: "bold", textTransform: "none" }}
      startIcon={<CheckCircleIcon />}
      onClick={handleSubmit}
      variant="contained"
      color="primary"
    >
      {modalMode === "add" ? "Submit" : "Update"}
    </Button>
  </DialogActions>
</Dialog>

      <Dialog open={openDeleteConfirmation} onClose={handleCancelDelete}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this package?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<CancelIcon />}
            sx={{ fontWeight: "bold", textTransform: "none" }}
            onClick={handleCancelDelete}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            sx={{ fontWeight: "bold", textTransform: "none" }}
            variant="contained"
            startIcon={<DeleteIcon />}
            color="error"
          >
            {" "}
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openToast}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          variant="filled"
          onClose={handleToastClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={openConfirmation} onClose={handleCancel}>
        <DialogTitle>{isActive ? "Deactivate" : "Activate"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isActive
              ? "Are you sure you want to deactivate this item?"
              : "Are you sure you want to activate this item?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontWeight: "bold", textTransform: "none" }}
            startIcon={<CancelIcon />}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            sx={{ fontWeight: "bold", textTransform: "none" }}
            variant="contained"
            startIcon={<CheckCircleIcon />}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>



  );
}

export default FollowUP;