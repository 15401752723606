import React, { useState,useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer,DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
const DuePaymentModal = ({ open, onClose, onSubmit, dueAmount, memberId,paymentId }) => {
    const [payingAmount, setPayingAmount] = useState(dueAmount || '');
    const [offer, setOffer] = useState(0);
    const [modeOfPayment, setModeOfPayment] = useState('');
    const [dateOfPayment, setDateOfPayment] = useState(null);
  
    useEffect(() => {
      setPayingAmount(dueAmount);
    }, [dueAmount]);
  
    const handleSubmit = () => {
      onSubmit({
        member_id: memberId,
        payment_id:paymentId, // You can adjust this if needed
        paying_amount: payingAmount,
        offer,
        mode_of_payment: modeOfPayment,
        date_of_payment: dateOfPayment ? dateOfPayment.toISOString().split('T')[0] : ''
      });
    };
  
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Due Payment Details</DialogTitle>
      <DialogContent>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoItem  components={['DatePicker']}>
          <DatePicker
            label="Date of Payment"
            value={dateOfPayment}
            onChange={(newDate) => setDateOfPayment(newDate)}
            renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
          />
             </DemoItem>
        </LocalizationProvider>
        <TextField
          label="Paying Amount"
       
          fullWidth
          margin="normal"
          value={payingAmount}
          onChange={(e) => setPayingAmount(e.target.value)}
        />
        <TextField
          label="Offer"
          type="number"
          fullWidth
          margin="normal"
          value={offer}
          onChange={(e) => setOffer(Number(e.target.value))}
        />
        <TextField
          label="Mode of Payment"
          select
          fullWidth
          margin="normal"
          value={modeOfPayment}
          onChange={(e) => setModeOfPayment(e.target.value)}
        >
          <MenuItem value="phonepe">PhonePe</MenuItem>
          <MenuItem value="paypal">PayPal</MenuItem>
          <MenuItem value="credit_card">Credit Card</MenuItem>
          <MenuItem value="bank_transfer">Bank Transfer</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={handleSubmit} color="primary">Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DuePaymentModal;
