export const retrieveGymMember = async (
  token,
  conversation_id,
  userId,
  searchText,
  limit,
  index
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    id: conversation_id,
    user_id: userId,
    //   search_text: searchText,
    //   limit: limit,
    //   index: index
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/retrive-member`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve user: " + error.message);
  }
};

export const PtPackageDropdown = async (token, userId) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/trainer-package-drop-down`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};

export const PtPackageDropdownvalue = async (token, userId, id) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    id: id,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/trainer-package-value`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};
export const TrainerPayment = async (token, conversation_id, UseradminData) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    member_id: conversation_id,
    trainer_package_id: UseradminData.package,
    payble_amount: UseradminData.packagevalue,
    total_payble_amount: UseradminData.totalpaybleamount,
    offer:UseradminData.discount,
    mode_of_payment: UseradminData.modeofpayment,
    paying_amount: UseradminData.payingamount,
    date_of_payment: UseradminData.dateofpayment,
    start_date: UseradminData.activationdate,
    start_time: UseradminData.startTime,
    end_time: UseradminData.endTime,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/trainer-package-payment`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};

export const YearlyPackageAdd = async (
  token,
  conversation_id,
  startDate,
  endDate
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    member_id: conversation_id,
    start_date: startDate,
    end_date: endDate,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/add-yearly-package`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};

export const MembershipPause = async (
  token,
  conversation_id,
  startDate,
  userId
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    member_id: conversation_id,
    pause_date: startDate,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/membership-pause`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};
export const MembershipResume = async (
  token,
  conversation_id,
  startDate,
  userId
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    member_id: conversation_id,
    resume_date: startDate,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/membership-resume`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};
