// Tab1.js
import React from "react";
import { useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { useEffect, useRef } from "react";
import {
  Card,
  Button,
  Grid,
  TextField,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Paper,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { ButtonGroup } from "@mui/material";
import DateFilter from "./DateFilter.jsx";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import CircularProgress from "@mui/material/CircularProgress";
import MessageIcon from "@mui/icons-material/Message";
import EmailIcon from "@mui/icons-material/Email";
import ChatIcon from "@mui/icons-material/Chat";
import json2mq from "json2mq";
import { useNavigate } from "react-router-dom";
import {
  retrieveDueUser,
  AutocompleteDueUser,
} from "../Apihelper/DueUserApi.js";

const Tab1 = (props) => {
  const navigate = useNavigate();
  const { window1 } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // Default to 'success'
  const [options, setOptions] = useState([]);
  const [fetchdatarow, setetchdatarow] = useState([]);
  const [datelist, setDatelist] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [todate, settodate] = useState("");
  const [textsearch, settextsearch] = useState("");
  const dateRef = useRef();
  const [age, setAge] = React.useState("");
  useEffect(() => {
    const storedData = localStorage.getItem("userdata");
    if (!storedData) {
      // User is not logged in, no need to redirect
      navigate('/');
    }
    
  }, []);
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 600,
    })
  );

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenToast(true);
  };
  const handleToastClose = () => {
    setOpenToast(false);
  };

  const fetchData = async (value = "") => {
    setLoading(true);
    setHasMore(true);
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id; // Assuming you have a userId in userdata
      const searchText = value; // Example search text
      const limit = 10; // Example limit
      const index = 0; // Example index
      const res = await retrieveDueUser(
        userToken,
        userId,
        searchText,
        limit,
        index,
        datelist,
      fromDate,
      "",
      );

      if (res.code == "200") {
        setetchdatarow(res.data);
        console.log(res.data);
        setLoading(false);
        if (res.data.length >= 0 && res.data.length < 10) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      } else {
        handleSnackbarOpen(res.message, "error");
        // console.error(error); // Handle error
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // const rows = [
  //   createData('MTTMG231202205353', 'Jyotiranjan Sahoo', '7609942076', 'sahoorinku63@gmail.com', 4.0),
  //   createData('MTTMG231202205353', 'Jyotiranjan Sahoo', '7609942076', 'sahoorinku63@gmail.com', 4.3),
  //   createData('MTTMG231202205353', 'Jyotiranjan Sahoo', '7609942076', 'sahoorinku63@gmail.com', 6.0),
  //   createData('MTTMG231202205353', 'Jyotiranjan Sahoo', '7609942076', 'sahoorinku63@gmail.com', 4.3),
  //   createData('MTTMG231202205353', 'Jyotiranjan Sahoo', '7609942076', 'sahoorinku63@gmail.com', 3.9),
  // ];

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide all borders for all children
    "& td, & th": {
      border: 0,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = (event, value) => {
    const searchText = value || "";

    fetchData(searchText);
  };
  const handleInputChange = async (value) => {
    if (value.length >= 3) {
      try {
        const userData = localStorage.getItem("userdata");
        const userDataObj = JSON.parse(userData);
        const userToken = userDataObj.token;
        const userId = userDataObj.id; // Assuming you have a userId in userdata
        const searchText = value; // Example search text

        const res = await AutocompleteDueUser(userToken, userId, searchText);

        if (res.code == "200") {
          const dataArray = Object.values(res.data);

          if (Array.isArray(dataArray)) {
            const names = dataArray.map((item) => item.search_text);
            setOptions(names);
          } else {
            console.error("res.data is not an array:", res.data);
          }
        } else {
          handleSnackbarOpen(res.message, "error");
          //  console.error(error); // Handle error
        }
      } catch (error) {
        console.error(error);
        // Handle errors
      }
    }
  };
  const handleCardPress = async (member_id) => {
    try {
      navigate("/memberdetails", {
        state: {
          id: member_id,
          random_no: Math.random(),
        },
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLoadMore = async () => {
    // Calculate the next index based on the number of items loaded so far
    const nextIndex = fetchdatarow.length;

    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);

    try {
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const searchText = "";
      const limit = 10;

      const res = await retrieveDueUser(
        userToken,
        userId,
        searchText,
        limit,
        nextIndex,
        datelist,
        fromDate,
        todate,
      );

      if (res.code == "200") {
        // Append the newly loaded data to the existing data
        setetchdatarow((prevResults) => [...prevResults, ...res.data]);

        // Check if there are more results available
        if (res.data.length >= 0 && res.data.length < 10) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleApplydatecustom = async ({ fromDateval, toDateval,value = '' }) => {
    settodate(toDateval);
    setFromDate(fromDateval);
    setLoading(true);
    try {
      const storedUserData = localStorage.getItem("userdata");
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id; // Assuming you have a userId in userdata
      const searchText = value; // Example search text
      const limit = 10; // Example limit
      const index = 0; // Example index
    
      const data = await retrieveDueUser(
        userToken, 
        userId, 
        searchText, 
        limit,
         index,
        datelist,
        fromDateval,
        toDateval,
    
      );
      if (data.code == "200") {
        setetchdatarow(data.data)
       
        setLoading(false);
        if (data.data.length >= 0 && data.data.length <50) {
          setHasMore(false)
        }
        else {
          setHasMore(true);
  
        }
      }
      else{
        setetchdatarow([])
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // Handle the selected value in the parent page
  };
  const handleApplydate = async (selectedValue,value = '') => {
    setDatelist(selectedValue);
    setLoading(true);
    try {
      const storedUserData = localStorage.getItem("userdata");
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id; // Assuming you have a userId in userdata
      const searchText = value; // Example search text
      const limit = 10; // Example limit
      const index = 0; // Example index
      const data = await retrieveDueUser(
        userToken, 
        userId, 
        searchText, 
        limit,
         index,
         selectedValue,
        "",
        "",
      );
      if (data.code == "200") {
        setLoading(false);
        setetchdatarow(data.data)
      
        if ( data.data.length >= 0 && data.data.length <50) {
          setHasMore(false)
        }
        else {
          setHasMore(true);
  
        }

      }
      else{
        setetchdatarow([])
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // Handle the selected value in the parent page
  };
  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Box>
          <Autocomplete
            style={{ height: 50 }}
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            options={options}
            onInputChange={(event, value) => handleInputChange(value)} // Pass searchText to handleInputChange
            onChange={(event, value) => handleSearchChange(event, value)}
            renderInput={(params) => (
              <TextField
                size="small"
                sx={{ width: 300 }}
                {...params}
                label="Search input"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
            sx={{
              "& .MuiInputBase-root": {
                alignItems: "center",
                height: "50px",
              },
            }}
          />
        </Box>
        {/* <Box sx={{ margin: '0px 10px' }}>
                <FormControl sx={{ minWidth: 120, }}>
                  <InputLabel id="demo-simple-select-helper-label">PACKAGE</InputLabel>
                  <Select
                    sx={{ minWidth: 120, height: '50px' }}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={age}
                    label="PACKAGE"
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>

                </FormControl>
              </Box> */}
        <Box>
          <FormControl>
            <DateFilter ref={dateRef} 
            onApplycustomdate={handleApplydatecustom}
            onApply={handleApplydate}
            />
          </FormControl>
        </Box>
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          overflow: "hidden",
          margin: "20px 0",
        }}
      >
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: '#2f80ed',
              }}
            >
              <TableCell
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
                  
                }}
              >
                Membership Id
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
                 
                }}
              >
                Name
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
                 
                }}
              >
                Phone No.&nbsp;
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
                  
                }}
              >
                Email&nbsp;
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
                
                }}
              >
                Due Amount&nbsp;
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
                
                }}
              >
                GENDER &nbsp;
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
                 
                }}
              >
                PACKAGE NAME &nbsp;
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
                 
                }}
              >
                DUE DATE &nbsp;
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  color: "#fff",
                  fontWeight: "bold",
                  borderBottom: "none",
                 
                }}
              >
                Action&nbsp;
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fetchdatarow.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                  "&:hover": {
                    backgroundColor: "#e3f2fd",
                    cursor: "pointer",
                  },
                }}
              >
                <TableCell
                  sx={{
                   
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  }}
                  onClick={() => handleCardPress(row.member_id)}
                >
                  <Typography
                    sx={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {row.membership_number}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                  
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  }}
                  align="left"
                >
                  {row.name}
                </TableCell>
                <TableCell
                  sx={{
                
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  }}
                  align="left"
                >
                  {row.phone}
                </TableCell>
                <TableCell
                  sx={{
                   
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  }}
                  align="left"
                >
                  {row.email}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "16px",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  }}
                  align="left"
                >
                  {row.due}
                </TableCell>
                <TableCell
                  sx={{
                   
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  }}
                  align="left"
                >
                  {row.gender}
                </TableCell>
                <TableCell
                  sx={{
                  
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  }}
                  align="left"
                >
                  {row.package_name}
                </TableCell>
                <TableCell
                  sx={{
                  
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  }}
                  align="left"
                >
                  {row.end_date}
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <EmailIcon color="primary" />
                  <ChatIcon color="primary" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ textAlign: "center", marginTop: "10px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLoadMore}
          disabled={
            !hasMore || (fetchdatarow.length < 10 && fetchdatarow.length !== 0)
          }
          sx={{ fontWeight: "bold", textTransform: "none" }}
        >
          {fetchdatarow.length === 0 || fetchdatarow.length < 10
            ? "No More Records"
            : "Load More"}
        </Button>
      </div>
      <Snackbar
        open={openToast}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          variant="filled"
          onClose={handleToastClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Tab1;
