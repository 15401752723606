export const AutoInvoiceNumber = async (token, userId) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/get-invoice-number`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};

export const MemberDuePayment = async (token, userId, FormData) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    member_id: FormData.member_id,
    payment_id: FormData.payment_id,
    paying_amount: FormData.payingAmount,
    mode_of_payment: FormData.modeofpayment,
    date_of_payment: FormData.dateofpayment,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/member-due-payment`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};



export const MembeRenwalPackage = async (token, userId, conversation_id,FormData) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    member_id: conversation_id,
    package_id: FormData.package_id,
    offer:FormData.offer,
    payble_amount: FormData.payble_amount,
    total_payble_amount: FormData.total_payble_amount,
    paying_amount: FormData.paying_amount,
    mode_of_payment: FormData.mode_of_payment,
    date_of_payment: FormData.date_of_payment,
    start_date: FormData.start_date,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/renew-package`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};
export const MembeRepeatPackage = async (userToken, userId, conversation_id, packageIdrepeat, repeatModeOfPayment, repeatOffer) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${userToken}`);

  const raw = JSON.stringify({
    user_id: userId,
    member_id: conversation_id,
    package_id: packageIdrepeat,
    mode_of_payment: repeatModeOfPayment,
    offer: repeatOffer
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/add/same-package`,
      requestOptions
    );

    // Check if the response is ok (status code 200-299)
    if (!response.ok) {
      // Log the response for debugging
      const errorText = await response.text();  // Get the raw response text
      console.error("Response error:", errorText);
      throw new Error(`HTTP error! Status: ${response.status}, Response: ${errorText}`);
    }

    // Attempt to parse the response as JSON
    const result = await response.json();
    return result;
  } catch (error) {
    // Log the full error message for debugging
    console.error("Fetch error:", error);
    throw new Error("Failed to retrieve package: " + error.message);
  }
};


