import React, { useEffect, useState } from 'react';
import {
  PieChart,
  Pie,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer
} from 'recharts';

import {
  DashboardDuepieChart
} from "../Apihelper/Dashboard.js";

const PieChartComponent = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      setLoading(true);
      try {
        const userData = JSON.parse(localStorage.getItem("userdata"));
        const { token, id: userId } = userData;
        const response = await DashboardDuepieChart(token, userId); // Fetch the data from your API

        if (response.code === 200) {
          setData(response.data);
        } else {
          setError(response.message || 'Failed to fetch dashboard data');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const COLORS = ['#8e44ad', '#00C49F', '#FFBB28','#00fefe','#12FFAA'];

  return (
    <ResponsiveContainer width={300} height={300}>
      <PieChart>
        <Pie
          data={data}
          dataKey="total" // This should be the key for the value in your data
          nameKey="name" // This should be the key for the name in your data
          cx="50%"
          cy="50%"
          outerRadius={80}
          label
        >
          {/* Define custom colors for your slices */}
          {data.map((entry, index) => (
      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
    ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartComponent;
