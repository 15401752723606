
import * as React from "react";
import Tooltip from '@mui/material/Tooltip';
import Table from "@mui/material/Table";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import Drawer from "@mui/material/Drawer";
import { Avatar, ButtonGroup } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Header from '../Includes/Header';
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { Card, Button, Grid, TextField,Snackbar,Alert, FormControl, InputLabel, Select, MenuItem, FormHelperText, Paper } from "@mui/material";
import { useLocation } from 'react-router-dom';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Menu from '@mui/material/Menu';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer,DemoItem } from '@mui/x-date-pickers/internals/demo';
import CircularProgress from "@mui/material/CircularProgress";
import json2mq from "json2mq";

import {
  AutoInvoiceNumber,
  MemberDuePayment,
} from "../Apihelper/ApiHelperPayment";


import { useNavigate } from "react-router-dom";

const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 600,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide the card container on small screens (mobile view)
    },
  },
  card: {
    width: "100%",
    margin: "8px",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px", // Adjust the card width as needed
    },
  },
}));
const drawerWidth = 292;

function Payment(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [openToast, setOpenToast] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Default to 'success'
  
  const { paymentData } = location.state;
  const [FormData, setFormData] = useState({
    
    invoice_number: '',
    modeofpayment:'',
    payingAmount:'',
    dateofpayment: null,
    member_id:'',
    payment_id:'',
  });

  const { window1 } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

 
  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 600,
    })
  );

 

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window1 !== undefined ? () => window().document.body : undefined;

  // useEffect(() => {
  //   // Perform localStorage action
  //   const item = localStorage.getItem('userdata')
  //   if (item ==null){
  //      setredirectpage(true);
  //   }
  //   //console.log("tapan"+item);
  // }, [])
  // if (redirectpage) {

  //   return redirect('/');
  // }

  const drawer = (
    <div className="d-flex flex-column h-100">
      <Header />
    </div>
  );
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
 
 
 
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenToast(true);
  };
  const handleToastClose = () => {
    setOpenToast(false);
  };
  const fetchData = async () => {
    try {
        const userData = localStorage.getItem("userdata");
        const userDataObj = JSON.parse(userData);
        const userToken = userDataObj.token;
        const userId = userDataObj.id; 
        const res = await AutoInvoiceNumber(userToken, userId);

        if (res.code == "200") {
         
            setFormData({ ...FormData, invoice_number: res.data });
          
        } else {
           // handleSnackbarOpen(res.message, 'error');
           console.error(error); 
        }
    } catch (error) {
        console.error(error);
        // Handle errors
    }
};
useEffect(() => {
 
  fetchData();
}, []);
const handlePayingAmountChange = (event, dueAmount) => {
  let inputValue = event.target.value;
  
  // Ensure that only numeric values are entered
  inputValue = inputValue.replace(/\D/g, ''); // Remove non-numeric characters
  
  // Ensure that the paying amount is less than or equal to the due amount
  if (inputValue <= dueAmount) {
    // Update the payingAmount field in FormData
    setFormData({ ...FormData, payingAmount: inputValue });
  }
};
 
const handleSubmit = async (member_id, payment_id) => {
  const userData = localStorage.getItem("userdata");
  const userDataObj = JSON.parse(userData);
  const userToken = userDataObj.token;
  const userId = userDataObj.id; 

  const requestData = {
    ...FormData,
    member_id: member_id, // Include memberId in the request payload
    payment_id: payment_id, // Include paymentId in the request payload
  };

  console.log(requestData);

  // Check if payingAmount is 0, if so, don't call the API
  if (FormData.payingAmount == 0) {
    handleSnackbarOpen('Paying amount cannot be zero.', 'error');
    return; // Exit the function to prevent the API call
  }

  try {
    const res = await MemberDuePayment(userToken, userId, requestData);
    if (res.code == "200") {
      handleSnackbarOpen(res.message, 'success');
      setFormData({
        invoice_number: '',
        modeofpayment: '',
        payingAmount: '',
        dateofpayment: null,
      });
     
      navigate('/memberdetails', {
        state: {
          id: member_id,
          random_no: Math.random(),
        },
      });
     
    } else {
      handleSnackbarOpen(res.message, 'error');
    }
  } catch (error) {
    console.error(error); // Handle error
  }
};

React.useEffect(() => {
  const storedData = localStorage.getItem("userdata");
  if (!storedData) {
    // User is not logged in, no need to redirect
    navigate('/');
  }
  
}, []);
  return (
    <Box sx={{ display: 'flex', backgroundColor: "#EFEDF1", minHeight: '100vh' }}>
      <Header />
      <CssBaseline />
      <Box component="main" sx={{ flexGrow: 1, overflowY: 'auto', padding: '20px', }}>
        <div style={{ display: 'flex', flexGrow: 1, marginTop: '50px' }}>
          <div style={{ flex: 1, marginTop: '15px' }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
          size="small"
    variant="contained" // Use contained for a filled look
    startIcon={<ArrowBackIcon sx={{ fontSize: 20 }} />} // Slightly larger icon
    sx={{
      textTransform: "none",
      fontWeight: 600,
      marginRight: 1,
      backgroundColor: '#3f51b5', // Deep blue color
      color: 'white', // White text for contrast
      borderRadius: 50, // Rounded pill shape for a classy look
    
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
      '&:hover': {
        backgroundColor: '#303f9f', // Darker shade on hover
        boxShadow: '0 6px 8px rgba(0, 0, 0, 0.2)', // Enhanced shadow on hover
      },
    }}
    onClick={() => navigate(-1)}
  >
    Go Back
  </Button>
    <Typography variant="h6" component="h6">
    Due payment
    </Typography>
  </Box>
            {Object.keys(paymentData).map((key, index) => (
            <Card sx={{ marginTop: '20px', marginBottom: '10px' }}>
               
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
 
  <Avatar sx={{ height: '100px', width: '100px' }} />
</Box>

              <Grid container spacing={3} sx={{  marginBottom: '10px', paddingX: '20px' }}>
                <Grid item xs={4}>
                  
                  <Grid item xs={12}>
                  
                    <TextField label="Due Amount" inputProps={{ readOnly: true }} fullWidth  value={paymentData[key].due}/>
                  </Grid>
                </Grid>
                {/* Second Row */}
                <Grid item xs={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                    <TextField label="Name" inputProps={{ readOnly: true }} fullWidth placeholder=" Enter Name" value={paymentData[key].name}/>
                 
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                    <TextField label="Phone" inputProps={{ readOnly: true }} fullWidth placeholder=" Enter Phone" value={paymentData[key].phone}/>
                     
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ marginTop: '20px', marginBottom: '20px', paddingX: '20px' }}>
                <Grid item xs={4}>
                  <Grid item xs={12}>
                  <TextField label="Paying Amount" fullWidth placeholder="Enter Paying Amount..." value={FormData.payingAmount} 
            onChange={(event) => handlePayingAmountChange(event, paymentData[key].due)}
            error={parseFloat(FormData.payingAmount) > paymentData[key].due} 
            helperText={parseFloat(FormData.payingAmount) > paymentData[key].due ? "Paying amount cannot be greater than due amount" : null}
         
                 />
                  
                  </Grid>
                </Grid>
                {/* Second Row */}
                <Grid item xs={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                 
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoItem  components={['DatePicker']}>
          <DatePicker
                        value={FormData.dateofpayment}
                        onChange={(date) => setFormData({ ...FormData, dateofpayment: date })} // Update 'dob' state in formData
                     
           label="Payment Date"
            format="DD/MM/YYYY"
            clearable
           
           
          />
                </DemoItem>
        </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                    <TextField label="Invoice No"inputProps={{ readOnly: true }} fullWidth value={FormData.invoice_number}  placeholder="invoice number" />
                    
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ marginTop: '20px', marginBottom: '20px', paddingX: '20px' }}>

               
                {/* Second Row */}
               
                <Grid item xs={4}>
                <Grid item xs={12}>
                    <FormControl sx={{ minWidth: 120, }} fullWidth>
                  <InputLabel id="demo-simple-select-helper-label">Mode of Payment</InputLabel>
                  <Select
                  fullWidth
                    sx={{ minWidth: 120, height: '50px' }}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={FormData.modeofpayment}
                    onChange={(e) => setFormData({ ...FormData, modeofpayment: e.target.value })}
                    label="Mode of Payment"
                  
                  >
                    {/* <MenuItem value="">
                      <em>Select Payment Type</em>
                    </MenuItem> */}
                     <MenuItem value="paytm">Paytm</MenuItem>
        <MenuItem value="googlepay">Google Pay</MenuItem>
        <MenuItem value="phonepe">PhonePe</MenuItem>
        <MenuItem value="cash">Cash</MenuItem>
        <MenuItem value="card"> Card</MenuItem>
                  </Select>
                </FormControl>
                    </Grid>
                </Grid>
              </Grid>

              <Box  sx={{ display: 'flex',  alignItems: "center", margin: '15px', justifyContent: 'space-between' }}><Button sx={{ textTransform: 'none', whiteSpace: 'nowrap', fontWeight: 700 }} variant="outlined" type="reset">Cancel</Button><Button  onClick={() => handleSubmit(paymentData[key].member_id,paymentData[key].payment_id)} sx={{ textTransform: 'none', whiteSpace: 'nowrap', fontWeight: 700 }}variant="contained">Payment</Button>
              </Box>

            </Card>

          ))}
          </div>

        </div>

      </Box>




      <Snackbar
        open={openToast}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert  variant="filled" onClose={handleToastClose} severity={snackbarSeverity}>
        {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>



  );
}

export default Payment;