
import * as React from "react";
import Tooltip from '@mui/material/Tooltip';
import Table from "@mui/material/Table";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import {
    createEmployeesDetails
} from "../Apihelper/ApiEmployeesDetails";

import Typography from "@mui/material/Typography";
import Header from '../Includes/Header';
import { styled } from "@mui/material/styles";

import { useState } from "react";
import { useEffect, useRef } from "react";
import { Card,Snackbar,Alert, Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText, Paper } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { DemoContainer,DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Menu from '@mui/material/Menu';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";

import CircularProgress from "@mui/material/CircularProgress";
import json2mq from "json2mq";




import { useNavigate } from "react-router-dom";

const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 600,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide the card container on small screens (mobile view)
    },
  },
  card: {
    width: "100%",
    margin: "8px",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px", // Adjust the card width as needed
    },
  },
}));
const drawerWidth = 292;

function AddEmployees(props) {
  const navigate = useNavigate();
  const { window1 } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const dateRef = useRef();


  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 600,
    })
  );




  const container =
    window1 !== undefined ? () => window().document.body : undefined;

  // useEffect(() => {
  //   // Perform localStorage action
  //   const item = localStorage.getItem('userdata')
  //   if (item ==null){
  //      setredirectpage(true);
  //   }
  //   //console.log("tapan"+item);
  // }, [])
  // if (redirectpage) {

  //   return redirect('/');
  // }

  const drawer = (
    <div className="d-flex flex-column h-100">
      <Header />
    </div>
  );

  const [selectedFile, setSelectedFiles] = useState([]);
  const [openToast, setOpenToast] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Default to 'success'
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [UseradminData, setUseradminData] = useState({
    selectedFile: null,
    address:'',
    fullname:'',
    email:'',
    phone:'',
    bloodgroup:'',
    designation:'',
    salary:'',
    batchnumber:'',
    joiningdate:null
  });
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenToast(true);
  };
  const handleToastClose = () => {
    setOpenToast(false);
  };

  const handleChange = (e) => {
    setUseradminData({
      ...UseradminData,
      [e.target.name]: e.target.value,
    });
  };
 
  const handleSubmit = async () => {
    console.log(UseradminData)
    // return false;
    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
const userToken = userDataObj.token;
 
   
      try {
        const res = await createEmployeesDetails(userToken,UseradminData);
        if (res.code == "200") {
             
            handleSnackbarOpen(res.message, 'success');
            setUseradminData({
                selectedFile:null,
                address:'',
              fullname:'',
              email:'',
              phone:'',
              bloodgroup:'',
              designation:'',
              salary:'',
              batchnumber:"",
              joiningdate:null
          });
          setTimeout(() => {
        
            navigate('/employeesdetail');
        }, 3000);
          } else {
            handleSnackbarOpen(res.message, 'error');
         //   console.error(error); // Handle error
          }
      } catch (error) {
        console.error(error); // Handle error
      }
   
   
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUseradminData({ ...UseradminData, selectedFile: file });
  };


  const handleDragOver = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    const storedData = localStorage.getItem("userdata");
    if (!storedData) {
      // User is not logged in, no need to redirect
      navigate('/');
    }
    
  }, []);
 
  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    setSelectedFiles([...selectedFile, ...files]);
    // Update FormData state to include selected files
    setFormData({ ...UseradminData, selectedFile: [...UseradminData.selectedFile, ...files] });
  };
  
  return (
    <Box sx={{ display: 'flex', backgroundColor: "#EFEDF1", minHeight: '100vh' }}>
      <Header />
      <CssBaseline />
      <Box component="main" sx={{ flexGrow: 1, overflowY: 'auto', padding: '20px', }}>
        <div style={{ display: 'flex', flexGrow: 1, marginTop: '50px' }}>
          <div style={{ flex: 1, marginTop: '15px' }}>

            <Box sx={{display:'flex',justifyContent:'space-between'}}>
                {/* First table in the first row */}
              
                <Box sx={{ display: "flex", alignItems: "center" }}>
    <Button
      variant="contained"
      startIcon={<ArrowBackIcon />}
      sx={{
        textTransform: "none",
        fontWeight: 700,
        marginRight: 2, // Adjust this value as needed
      }}
      onClick={() => navigate(-1)}
    >
      Go Back
    </Button>
  
    <Typography variant="h5" component="h6">
                Add Employees
                </Typography>
  </Box>
                {/* Second table in the first row */}
                <Button variant="contained" sx={{ textTransform: 'none', whiteSpace: 'nowrap', fontWeight: 700 }} onClick={() => navigate('/employeesdetail')}>View List
                </Button>
            </Box>

            
          
            <Card sx={{ marginTop: '20px', marginBottom: '20px' }}>
      <Grid container spacing={3} sx={{ marginTop: '20px', marginBottom: '20px', paddingX: '20px' }}>
      <Grid item xs={4}>
          <TextField name="fullname" label="Name" fullWidth placeholder=" Enter Name" value={UseradminData.fullname} onChange={handleChange} />
        </Grid>
        <Grid item xs={4}>
          <TextField name="phone" label="Phone" fullWidth placeholder="Enter Phone..." value={UseradminData.phone} onChange={handleChange} />
        </Grid>
       
        <Grid item xs={4}>
          <TextField name="email" label="Email" fullWidth placeholder=" Enter Email" value={UseradminData.email} onChange={handleChange} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: '20px', marginBottom: '20px', paddingX: '20px' }}>
      <Grid item xs={4}>
          <TextField name="address" multiline label="Address" fullWidth placeholder=" Enter Address" value={UseradminData.address} onChange={handleChange} />
        </Grid>
       
        <Grid item xs={4}>
          <FormControl sx={{ minWidth: 120, }} fullWidth>
            <InputLabel id="demo-simple-select-helper-label">Designation</InputLabel>
            <Select
              fullWidth
              sx={{ minWidth: 120, height: '50px' }}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={UseradminData.designation}
              name="designation"
              label="Designation"
              onChange={handleChange}
            >
              <MenuItem value="">Select Designation</MenuItem>
              <MenuItem value="manager">Manager</MenuItem>
              <MenuItem value="trainer">Trainer</MenuItem>
              <MenuItem value="perfectclining">PERFECT CLINING</MenuItem>
              <MenuItem value="supervisor">Supervisor</MenuItem>
              <MenuItem value="pujarinana">Pujari Nana</MenuItem>
              
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <TextField  name="salary"
label="Salary" fullWidth  placeholder="Salary"   value={UseradminData.salary} 
     onChange={handleChange}
           />
           
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: '20px', marginBottom: '20px', paddingX: '20px' }}>  
        <Grid item xs={4}>
          <TextField name="bloodgroup" label="Blood Group " fullWidth placeholder="Blood Group"  value={UseradminData.bloodgroup}onChange={handleChange}/>
        </Grid>
        <Grid item xs={4}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoItem  components={['DatePicker']}>
          <DatePicker
                        value={UseradminData.joiningdate}
                        onChange={(date) => setUseradminData({ ...UseradminData, joiningdate: date })} // Update 'dob' state in formData
                     
           label="joining Date"
            format="DD/MM/YYYY"
            clearable
           
           
          />
                </DemoItem>
        </LocalizationProvider>
       
        </Grid>
        <Grid item xs={4}>
          <TextField name="batchnumber" label="Card Number" fullWidth placeholder="Enter Card Number..." value={UseradminData.batchnumber} onChange={handleChange} />
        </Grid>
      
                
      </Grid>
      <Box sx={{ display: 'flex', alignItems: "center", margin: '15px', justifyContent: 'space-between' }}>
        <Button onClick={handleSubmit} variant="outlined" sx={{ textTransform: 'none', whiteSpace: 'nowrap', fontWeight: 700 }} type="reset">Cancel</Button>
        <Button sx={{ textTransform: 'none', whiteSpace: 'nowrap', fontWeight: 700 }} variant="contained" onClick={handleSubmit}>Add</Button>
      </Box>
    </Card>
          </div>

        </div>

      </Box>



      <Snackbar
        open={openToast}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert  variant="filled" onClose={handleToastClose} severity={snackbarSeverity}>
        {snackbarMessage}
        </Alert>
      </Snackbar>

    </Box>



  );
}

export default AddEmployees;