export const createOfferPackage = async (userToken, UseradminData) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const requestBody = {
      name: UseradminData.fullname,
      value: UseradminData.value,
      description: UseradminData.description,
      quantity: UseradminData.quantity, // Assuming duration is a number
      duration: UseradminData.duration, // Assuming package_amount is a number
      status: "1", // Assuming admission_value is a number
    };

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(requestBody),
      redirect: "follow",
    };

    const response = await fetch(
      `${window.constants.api_url}/create-offer-package`,
      requestOptions
    );
    const result = await response.json(); // Parse response as JSON
    return result;
  } catch (error) {
    throw new Error("Failed to create create-offer-package: " + error.message);
  }
};

export const UpdateOfferPackage = async (userToken, offerPackageData) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const requestBody = {
      id: offerPackageData.id,
      name: offerPackageData.name,
      value: offerPackageData.value,
      description: offerPackageData.description,
      quantity: offerPackageData.quantity,
      duration: offerPackageData.duration,
      status: offerPackageData.status, // Assuming status is part of the offer package data
    };

    const requestOptions = {
      method: "POST", // Or "PUT" if you are updating an existing offer package
      headers: myHeaders,
      body: JSON.stringify(requestBody),
      redirect: "follow",
    };

    const response = await fetch(
      `${window.constants.api_url}/update-offer-package`,
      requestOptions
    );
    const result = await response.json(); // Parse response as JSON
    return result;
  } catch (error) {
    throw new Error("Failed to update offer package: " + error.message);
  }
};
export const DeleteOfferpackage = async (token, userId, id) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    id: id,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/delete-offer-package`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};

export const AutocompleteOfferPackage = async (token, userId, searchText) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    search_text: searchText,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/offer/auto-complete-offer-package`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};
