export const retrieveDueUser = async (
  token,
  userId,
  searchText,
  limit,
  index,
  datelist, fromDate, toDate
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const payload = {
    user_id: userId,
    search_text: searchText,
    limit: limit,
    index: index,
    date: {
      type: "1",
      value: datelist, // Assuming datelist is defined correctly
    },
  };
  
  // Check if fromDate and toDate are not empty and modify the payload accordingly
  if (fromDate !== "" && toDate !== "") {
    payload.date = {
      type: "2",
      value: `${fromDate},${toDate}`, // Combine the two dates into a single string
    };
  }
  
  // Convert the payload object to a JSON string for the API call
  const raw = JSON.stringify(payload);
  
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/list-due-user`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve user: " + error.message);
  }
};
export const AutocompleteDueUser = async (token, userId, searchText) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    search_text: searchText,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/payment/due-member-autocomplete`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};

export const retrieveExpireMember = async (
  token,
  userId,
  searchText,
  limit,
  index,
  datelist, fromDate, toDate
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const payload = {
    user_id: userId,
    search_text: searchText,
    limit: limit,
    index: index,
    date: {
      type: "1",
      value: datelist, // Assuming datelist is defined correctly
    },
  };
  
  // Check if fromDate and toDate are not empty and modify the payload accordingly
  if (fromDate !== "" && toDate !== "") {
    payload.date = {
      type: "2",
      value: `${fromDate},${toDate}`, // Combine the two dates into a single string
    };
  }
  
  // Convert the payload object to a JSON string for the API call
  const raw = JSON.stringify(payload);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/expired-member`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve user: " + error.message);
  }
};
export const retrieveExpirePtMember = async (
  token,
  userId,
  searchText,
  limit,
  index
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    search_text: searchText,
    limit: limit,
    index: index,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/expired-member`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve user: " + error.message);
  }
};
export const MemberExpireDUserauto = async (
  token,
  userId,
  searchText,
  limit,
  index
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    search_text: searchText,
    limit: limit,
    index: index,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/payment/expired-member-auto-complete`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};
export const MemberExpireByDate = async (
  token,
  userId,
  searchText,
  limit,
  index,
  datelist, fromDate, toDate
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  const payload = {
    user_id: userId,
    search_text: searchText,
    limit: limit,
    index: index,
    date: {
      type: "1",
      value: datelist, // Assuming datelist is defined correctly
    },
  };
  
  // Check if fromDate and toDate are not empty and modify the payload accordingly
  if (fromDate !== "" && toDate !== "") {
    payload.date = {
      type: "2",
      value: `${fromDate},${toDate}`, // Combine the two dates into a single string
    };
  }
  
  // Convert the payload object to a JSON string for the API call
  const raw = JSON.stringify(payload);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/check-due-date`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};
export const PtExpireMember = async (
  token,
  userId,
  searchText,
  limit,
  index,
  datelist, fromDate, toDate
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const payload = {
    user_id: userId,
    search_text: searchText,
    limit: limit,
    index: index,
    date: {
      type: "1",
      value: datelist, // Assuming datelist is defined correctly
    },
  };
  
  // Check if fromDate and toDate are not empty and modify the payload accordingly
  if (fromDate !== "" && toDate !== "") {
    payload.date = {
      type: "2",
      value: `${fromDate},${toDate}`, // Combine the two dates into a single string
    };
  }
  
  // Convert the payload object to a JSON string for the API call
  const raw = JSON.stringify(payload);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/payment/expired-pt-package`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};

export const PtDuePayment = async (token, userId, searchText, limit, index,datelist, fromDate, toDate) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const payload = {
    user_id: userId,
    search_text: searchText,
    limit: limit,
    index: index,
    date: {
      type: "1",
      value: datelist, // Assuming datelist is defined correctly
    },
  };
  
  // Check if fromDate and toDate are not empty and modify the payload accordingly
  if (fromDate !== "" && toDate !== "") {
    payload.date = {
      type: "2",
      value: `${fromDate},${toDate}`, // Combine the two dates into a single string
    };
  }
  
  // Convert the payload object to a JSON string for the API call
  const raw = JSON.stringify(payload);
  
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/payment/due-pt-package`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};

export const PtExpireAutocomplete = async (token, userId, searchText) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    search_text: searchText,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/payment/expired-pt-package-autocomplete`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};
export const MainPackagDueDateAutocomplete = async (
  token,
  userId,
  searchText
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    search_text: searchText,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/payment/due-member-by-date-auto-complete`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};

export const PtdueAutocomplete = async (token, userId, searchText) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    search_text: searchText,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/payment/due-pt-package-autocomplete`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};


export const PtduePaymentClear = async (token, userId,UseradminData) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);


  const requestBody = {
    member_id: UseradminData.member_id,
    payment_id: UseradminData.payment_id,
    paying_amount: UseradminData.paying_amount,
    offer: 0, // Assuming duration is a number
    mode_of_payment: UseradminData.mode_of_payment,
    date_of_payment: UseradminData.date_of_payment,
    
     // Assuming package_amount is a number
 
  };
  // const raw = JSON.stringify({
  //   user_id: userId,
  //   search_text: searchText,
  // });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(requestBody),
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/payment/trainer-due-payment`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};
