// PieChart.jsx

import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import React, { useEffect, useState } from 'react';

import {
  DashboardCount,
  DashboardpieChart
} from "../Apihelper/Dashboard.js";
// const data = [
//   { name: 'Service', value: 400 },
//   { name: 'Material', value: 300 },
//   { name: 'Coal', value: 300 },
//   { name: 'Cash & Bank', value: 300 },
 
// ];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28','#00fefe','#12FFAA'];

const PieChartComponent = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchDashboardData = async () => {
      setLoading(true);
      try {
        const userData = JSON.parse(localStorage.getItem("userdata"));
        const { token, id: userId } = userData;
        const data = await DashboardpieChart(token, userId);
        if (data.code === 200) {
          setData(data.data);
        } else {
          setError(data.message || 'Failed to fetch dashboard data');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchDashboardData();
  }, []);
  return (
<PieChart width={300} height={300}>
  <Pie
    dataKey="value"
    data={data}
    cx="50%"
    cy="50%"
    outerRadius={90}
    innerRadius={50} // Add this for donut effect
    fill="#8884d8"
    label
  >
    {data.map((entry, index) => (
      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
    ))}
  </Pie>
  <Tooltip />
   <Legend 
    wrapperStyle={{ fontSize: '10px' }} // Set the font size here
  />
</PieChart>

  );
};

export default PieChartComponent;
