import "./memberlist.css";
import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CssBaseline from "@mui/material/CssBaseline";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Drawer from "@mui/material/Drawer";
import { ButtonGroup } from "@mui/material";
import DateFilter from "./DateFilter.jsx";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Header from "../Includes/Header";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TableRow from "@mui/material/TableRow";

import { useState } from "react";
import { useEffect, useRef } from "react";
import {
  Card,
  Button,
  Grid,
  TextField,
  FormControl,
  Snackbar,
  Alert,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Paper,
} from "@mui/material";
import {
  createNewMember,
  AutoMembershipNumber,
  PackageDropdown,
  PackageDropdownvalue,
} from "../Apihelper/MemberApiHelpr";
import Menu from "@mui/material/Menu";import {
  PackageadmissionDropdown,
  PackageAdmissionDropdownvalue,
} from "../Apihelper/AdmissionValueApi.js";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";

import CircularProgress from "@mui/material/CircularProgress";
import json2mq from "json2mq";

import { useNavigate } from "react-router-dom";

const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 600,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide the card container on small screens (mobile view)
    },
  },
  card: {
    width: "100%",
    margin: "8px",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px", // Adjust the card width as needed
    },
  },
}));
const drawerWidth = 292;

function MemberList(props) {
  const navigate = useNavigate();

  const { window1 } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // Default to 'success'
  const [Dropdowndata, setDropdowndata] = useState([]); // Default to 'success'
  const [packageamount, setpackageamount] = useState(""); // Default to 'success'
  const [Dropdownadmissiondata, setDropdownadmissiondata] = useState([]); // Default to 'success'
  const dateRef = useRef();
  React.useEffect(() => {
    const storedData = localStorage.getItem("userdata");
    if (!storedData) {
      // User is not logged in, no need to redirect
      navigate("/");
    }
  }, []);

  const [FormData, setFormData] = useState({
    selectedFile: null,
    membership_number: "",
    photo: "",
    phone: "",
    name: "",
    alter_phone: "",
    address: "",
    email: "",
    dob: null,
    wedding_date: null,
    occupation: "",
    height: "",
    weight: "",
    sex: "",
    package: "",
    packagename: "",

    packagevalue: "",
    totalpaybleamount: "",
    discount: "",
    payableamount: "",
    modeofpayment: "",
    payingamount: "",
    activationdate: null,
    dateofpayment: null,
    identification_type: "",
    identification_id: "",
    membershipvalue:"",
    membership:"",
    membership_packagename:""

  });

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 600,
    })
  );

  const handleChangesex = (event) => {
    setSex(event.target.value);
  };

  const [submitted, setSubmitted] = useState(false);

  const drawer = (
    <div className="d-flex flex-column h-100">
      <Header />
    </div>
  );

  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedFile, setSelectedFiles] = useState([]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...FormData, selectedFile: file });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    setSelectedFiles([...selectedFile, ...files]);
    // Update FormData state to include selected files
    setFormData({
      ...FormData,
      selectedFile: [...FormData.selectedFile, ...files],
    });
  };

  const handleDrag = (event) => {
    event.preventDefault();
    // Your logic to handle dragging files here
    const files = event.dataTransfer.files;
    setSelectedFiles([...selectedFile, ...files]);
    // Update FormData state to include dragged files
    setFormData({
      ...FormData,
      selectedFile: [...FormData.selectedFile, ...files],
    });
  };
  // const handleSave = () => {
  //   console.log(FormData); // Log all data from FormData to the console
  //   // Here you can perform any additional actions, such as sending the data to an API
  // };
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenToast(true);
  };
  const handleToastClose = () => {
    setOpenToast(false);
  };

  const handleSave = async () => {
    setSubmitted(true);
    console.log(FormData);
    const requiredFields = [
      "membership_number",
      "name",
      "phone",
      "address",
      "email",
      "dob",
      "height",
      "weight",
      "sex",
      "package",
      "packagevalue",
      "totalpaybleamount",
      "payableamount",
      "modeofpayment",
      "payingamount",
      "activationdate",
      "dateofpayment",
    ];
    const emptyFields = requiredFields.filter((field) => !FormData[field]);
    if (emptyFields.length > 0) {
      // Show error for empty fields

      return;
    }
    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;

    try {
      const res = await createNewMember(userToken, FormData);
      if (res.code == "200") {
        handleSnackbarOpen(res.message, "success");
        setFormData({
          selectedFile: null,
          membership_number: "",
          photo: "",
          phone: "",
          name: "",
          alter_phone: "",
          address: "",
          email: "",
          dob: null,
          wedding_date: null,
          occupation: "",
          height: "",
          weight: "",
          sex: "",
          package: "",
          packagevalue: "",
          totalpaybleamount: "",
          discount: "",
          payableamount: "",
          modeofpayment: "",
          payingamount: "",
          activationdate: null,
          dateofpayment: null,
          identification_type: "",
          identification_id: "",
          membershipvalue:"",
          membership:"",
          membership_packagename:""
        });
      
          // Navigate to Invoice Page with the FormData
          navigate('/memberList');
      
      } else {
        handleSnackbarOpen(res.message, "error");
        //   console.error(error); // Handle error
      }
    } catch (error) {
      console.error(error); // Handle error
    }
  };
  const fetchData = async () => {
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const res = await AutoMembershipNumber(userToken, userId);

      if (res.code == "200") {
        setFormData({ ...FormData, membership_number: res.data });
      } else {
        // handleSnackbarOpen(res.message, 'error');
        console.error(error);
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };
  const fetchDropdowndata = async () => {
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const res = await PackageDropdown(userToken, userId);

      if (res.code == "200") {
        setDropdowndata(res.data);
      } else {
        // handleSnackbarOpen(res.message, 'error');
        console.error(error);
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };

  const fetchDropdownAdmissiondata = async () => {
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const res = await PackageadmissionDropdown(userToken, userId);

      if (res.code == "200") {
        setDropdownadmissiondata(res.data);
      } else {
        // handleSnackbarOpen(res.message, 'error');
        console.error(error);
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };
  useEffect(() => {
    fetchDropdowndata();
    fetchDropdownAdmissiondata();
    fetchData();
  }, []);

  const handlepayblevalueChange = async (e) => {
    // Update state with the selected package
    const selectedPackageId = e.target.value;
    const selectedPackage = Dropdowndata.find(
      (row) => row.id === selectedPackageId
    );
    console.log(selectedPackage);
    setFormData({
      ...FormData,
      package: selectedPackageId,
      packagename: selectedPackage?.name || "",
    });

    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const res = await PackageDropdownvalue(
        userToken,
        userId,
        selectedPackageId
      );

      if (res.code == "200") {
        // Extract the package_amount from the first object in the array
        const packageAmount = res.data.package_amount;
       
        setFormData((prevState) => ({
          ...prevState,
          packagevalue: packageAmount,
        }));
       
       
      } else {
        // handleSnackbarOpen(res.message, 'error');
        console.error(error);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error if necessary
    }
  };
  const handlepayblevalueadmissionChange = async (e) => {
    // Update state with the selected package
    const selectedPackageId = e.target.value;
    const selectedPackage = Dropdownadmissiondata.find(
      (row) => row.id === selectedPackageId
    );
    console.log(selectedPackage);
    setFormData({
      ...FormData,
      membership: selectedPackageId,
      membership_packagename: selectedPackage?.name || "",
    });

    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const res = await PackageAdmissionDropdownvalue(
        userToken,
        userId,
        selectedPackageId
      );

      if (res.code == "200") {
        // Extract the package_amount from the first object in the array
        const packageAmount = res.data;
        // const total_payble_amount = res.data.total_payble_amount;
        setFormData((prevState) => {
          const totalpaybleamount =
            parseFloat(prevState.packagevalue || 0) + parseFloat(packageAmount || 0);
          
          return {
            ...prevState,
            membershipvalue: packageAmount,
            totalpaybleamount: totalpaybleamount,
          };
        });

        setpackageamount(totalpaybleamount);
      } else {
        // handleSnackbarOpen(res.message, 'error');
        console.error(error);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error if necessary
    }
  };

  const handleTotalPayableAmountChange = (e) => {
    const enteredValue = e.target.value;

    // If entered value is greater than the package amount, set it to the package amount
    const totalPayableAmount =
      enteredValue > packageamount ? packageamount : enteredValue;

    // Update state with the total payable amount
    setFormData({ ...FormData, totalpaybleamount: totalPayableAmount });
  };

  // Function to generate the helper text
  const generateHelperText = () => {
    return FormData.totalpaybleamount < packageamount
      ? "Total payable amount cannot exceed the package value"
      : "";
  };

  const aadhaarRegex = /^[2-9]{1}[0-9]{11}$/;
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

  const handleIdentificationChange = (e) => {
    const value = e.target.value;
    if (FormData.identification_type === "pan") {
      setFormData({ ...FormData, identification_id: value.toUpperCase() });
    } else {
      setFormData({ ...FormData, identification_id: value });
    }
  };

  const isAadhaarValid = aadhaarRegex.test(FormData.identification_id);
  const isPanValid = panRegex.test(FormData.identification_id);

  const isValid = () => {
    if (FormData.identification_type === "aadhaar") {
      return isAadhaarValid;
    }
    if (FormData.identification_type === "pan") {
      return isPanValid;
    }
    return false;
  };

  // Function to calculate payable amount

  const handleDiscountChange = (e) => {
    const discount = e.target.value;
    setFormData({
      ...FormData,
      discount: discount,
      payableamount: calculatePayableAmount(
        FormData.totalpaybleamount,
        discount
      ), // Calculate the payable amount
    });
  };

  // Function to calculate payable amount without using floats
  const calculatePayableAmount = (totalAmount, discount) => {
    const total = totalAmount ? Number(totalAmount) : 0;
    const discountValue = discount ? Number(discount) : 0;
    return (total - discountValue).toString(); // Subtract values and return as string
  };

  // Generate helper text based on validation logic
  const generateHelperTextDIS = () => {
    return FormData.totalpaybleamount > 0 &&
      FormData.discount > FormData.totalpaybleamount
      ? "Discount cannot be greater than total amount"
      : "";
  };
  return (
    <Box
      sx={{ display: "flex", backgroundColor: "#EFEDF1", minHeight: "100vh" }}
    >
      <Header />
      <CssBaseline />
      <Box
        component="main"
        sx={{ flexGrow: 1, overflowY: "auto", padding: "20px" }}
      >
        <div style={{ display: "flex", flexGrow: 1, marginTop: "50px" }}>
          <div style={{ flex: 1, marginTop: "15px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="contained"
                  startIcon={<ArrowBackIcon />}
                  sx={{
                    textTransform: "none",
                    fontWeight: 700,
                    marginRight: 2, // Adjust this value as needed
                  }}
                  onClick={() => navigate(-1)}
                >
                  Go Back
                </Button>

                <Typography variant="h5" component="h6">
                  New Joining
                </Typography>
              </Box>

              {/* Second table in the first row */}
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  whiteSpace: "nowrap",
                  fontWeight: 700,
                }}
                onClick={() => navigate("/memberlist")}
              >
                View List
              </Button>
            </Box>
            <Card sx={{ marginTop: "10px", marginBottom: "10px" }}>
              <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                <Grid
                  container
                  spacing={3}
                  sx={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    paddingX: "20px",
                  }}
                >
                  {/* First Row */}
                  <Grid item xs={4}>
                    {/* Combined text field */}
                    <div
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                      style={{
                        border: "2px dashed #ccc",
                        borderRadius: "10px",
                        height: "140px",
                        textAlign: "center",
                        cursor: "pointer",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center", // Center horizontally
                        justifyContent: "center", // Center vertically
                      }}
                    >
                      <div>
                        <div>
                          <img src="./file_upload.svg" alt="" />
                        </div>
                        <label style={{ color: "#003776" }}>
                          {" "}
                          <u>
                            Click to upload{" "}
                            <input
                              accept="image/*, application/pdf"
                              id="contained-button-file"
                              type="file"
                              onChange={handleFileChange}
                              style={{ display: "none" }} // Hide the input field
                            />
                          </u>{" "}
                          or drag and drop files here
                        </label>
                        {FormData.selectedFile && (
                          <Typography
                            variant="body2"
                            style={{ marginTop: "10px" }}
                          >
                            Selected file: {FormData.selectedFile.name}
                          </Typography>
                        )}
                        <div style={{ color: "#44474F", fontSize: "13px" }}>
                          Supported file formats: pdf & docx.
                        </div>
                      </div>
                    </div>
                  </Grid>
                  {/* Second Row */}
                  <Grid item xs={4}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          label="Enter Membership No..."
                          inputProps={{ readOnly: true }}
                          fullWidth
                          value={FormData.membership_number}
                          onChange={(e) =>
                            setFormData({
                              ...FormData,
                              membership_number: e.target.value,
                            })
                          }
                          error={submitted && !FormData.membership_number}
                          helperText={
                            submitted &&
                            !FormData.membership_number &&
                            "Membership No is required"
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Enter Name..."
                          fullWidth
                          value={FormData.name}
                          onChange={(e) =>
                            setFormData({ ...FormData, name: e.target.value })
                          }
                          error={submitted && !FormData.name}
                          helperText={
                            submitted && !FormData.name && "Name  is required"
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        {/* Adjusted text field to match the height */}
                        <TextField
                          label="Enter Phone..."
                          fullWidth
                          multiline
                          variant="outlined"
                          value={FormData.phone}
                          onChange={(e) =>
                            setFormData({ ...FormData, phone: e.target.value })
                          } // Display helper text if the field is empty
                          error={submitted && !FormData.phone}
                          helperText={
                            submitted &&
                            !FormData.phone &&
                            "phone No is required"
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Alternate Phone"
                          fullWidth
                          value={FormData.alter_phone}
                          onChange={(e) =>
                            setFormData({
                              ...FormData,
                              alter_phone: e.target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    {/* Combined text field */}

                    {/* Adjusted text field to match the height */}
                    <TextField
                      label="Permanent address"
                      multiline
                      rows={4}
                      fullWidth
                      variant="outlined"
                      value={FormData.address}
                      onChange={(e) =>
                        setFormData({ ...FormData, address: e.target.value })
                      }
                      error={submitted && !FormData.address}
                      helperText={
                        submitted && !FormData.address && "Address  is required"
                      }
                    />

                    <FormControl sx={{ marginTop: "35px" }} fullWidth>
                      <InputLabel id="sex-label">
                        Identification Type
                      </InputLabel>
                      <Select
                        labelId="sex-label"
                        label="Identification Type"
                        id="identification_type"
                        value={FormData.identification_type}
                        onChange={(e) =>
                          setFormData({
                            ...FormData,
                            identification_type: e.target.value,
                          })
                        }
                        fullWidth
                        error={submitted && !FormData.identification_type}
                      >
                        <MenuItem value="aadhaar">Aadhaar</MenuItem>
                        <MenuItem value="pan">Pan No</MenuItem>
                      </Select>
                      {submitted && !FormData.identification_type && (
                        <FormHelperText error>
                          Identification Type is required
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={8}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          label="Email"
                          fullWidth
                          value={FormData.email}
                          onChange={(e) =>
                            setFormData({ ...FormData, email: e.target.value })
                          }
                          error={submitted && !FormData.email}
                          helperText={
                            submitted && !FormData.email && "email  is required"
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid item xs={12}>
                          {/* Adjusted text field to match the height */}
                          <React.Fragment>
                            <Box>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoItem components={["DatePicker"]}>
                                  <DatePicker
                                    value={FormData.dob}
                                    onChange={(date) =>
                                      setFormData({ ...FormData, dob: date })
                                    } // Update 'dob' state in formData
                                    label="Dob"
                                    format="DD/MM/YYYY"
                                    clearable
                                    slotProps={{
                                      textField: {
                                        error: submitted && !FormData.dob,
                                        helperText:
                                          submitted &&
                                          !FormData.dob &&
                                          "Dob is required",
                                      },
                                    }}
                                  />
                                </DemoItem>
                              </LocalizationProvider>
                            </Box>
                          </React.Fragment>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        {/* Adjusted text field to match the height */}
                        <React.Fragment>
                          <Box>
                            <TextField
                              label="Identification Id"
                              fullWidth
                              variant="outlined"
                              value={FormData.identification_id}
                              onChange={handleIdentificationChange}
                              error={
                                submitted &&
                                (!FormData.identification_id || !isValid())
                              }
                              helperText={
                                submitted &&
                                (!FormData.identification_id
                                  ? "Identification ID is required"
                                  : !isValid() &&
                                    FormData.identification_type === "aadhaar"
                                  ? "Invalid Aadhaar ID"
                                  : !isValid() &&
                                    FormData.identification_type === "pan"
                                  ? "Invalid PAN ID"
                                  : "")
                              }
                            />
                          </Box>
                        </React.Fragment>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <hr style={{ borderTop: "2px solid #ccc" }} />
              <Typography sx={{ marginLeft: "10px" }} variant="h6">
                Body/Health
              </Typography>
              <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                <Grid
                  container
                  spacing={3}
                  sx={{ marginBottom: "10px", paddingX: "10px" }}
                >
                  <Grid item xs={4}>
                    <Grid item xs={12}>
                      <TextField
                        label="Height"
                        fullWidth
                        placeholder=" Enter Height"
                        value={FormData.height}
                        onChange={(e) =>
                          setFormData({ ...FormData, height: e.target.value })
                        }
                      />
                    </Grid>
                  </Grid>
                  {/* Second Row */}
                  <Grid item xs={4}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          label="Weight"
                          fullWidth
                          placeholder=" Enter Weight"
                          value={FormData.weight}
                          onChange={(e) =>
                            setFormData({ ...FormData, weight: e.target.value })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="sex-label">Sex</InputLabel>
                          <Select
                            labelId="sex-label"
                            label="sex"
                            id="sex"
                            value={FormData.sex}
                            onChange={(e) =>
                              setFormData({ ...FormData, sex: e.target.value })
                            } // Update 'sex' state in formData
                            placeholder="Select Sex..."
                            fullWidth
                            error={submitted && !FormData.sex}
                            helperText={
                              submitted && !FormData.sex && "sex  is required"
                            }
                          >
                            <MenuItem value="male">Male</MenuItem>
                            <MenuItem value="female">Female</MenuItem>
                            <MenuItem value="transgender">Transgender</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <hr style={{ borderTop: "2px solid #ccc" }} />
              <Typography sx={{ marginLeft: "10px" }} variant="h6">
                Payment
              </Typography>
              <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                <Grid
                  container
                  spacing={3}
                  sx={{ marginBottom: "10px", paddingX: "10px" }}
                >
                  <Grid item xs={4}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="package-label"> Package</InputLabel>
                        <Select
                          labelId="package-label"
                          id="package"
                          label="Package"
                          value={FormData.package}
                          onChange={handlepayblevalueChange}
                          placeholder="choose your Package..."
                          fullWidth
                        >
                          {Dropdowndata.map((row) => (
                            <MenuItem value={row.id}>{row.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Second Row */}
                  <Grid item xs={4}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          label="Package Value"
                          inputProps={{ readOnly: true }}
                          fullWidth
                          placeholder="Package Value"
                          value={FormData.packagevalue}
                          onChange={(e) =>
                            setFormData({
                              ...FormData,
                              packagevalue: e.target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="membership-label">Membership</InputLabel>
            <Select
              labelId="membership-label"
              id="membership"
              label="Membership"
              value={FormData.membership}
              onChange={handlepayblevalueadmissionChange}
              placeholder="Choose Membership"
              fullWidth
            >
              {/* Replace with actual membership options */}
              {Dropdownadmissiondata.map((row) => (
                            <MenuItem value={row.id}>{row.name}</MenuItem>
                          ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>

    <Grid item xs={4}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Membership Value"
            inputProps={{ readOnly: true }}
            fullWidth
            placeholder="Membership Value"
            value={FormData.membershipvalue}
            onChange={(e) =>
              setFormData({
                ...FormData,
                membershipvalue: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>
    </Grid>

                  <Grid item xs={4}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          label="Total payable Amount"
                          fullWidth
                          placeholder="Total payable Amount"
                          value={FormData.totalpaybleamount}
                          onChange={handleTotalPayableAmountChange}
                          helperText={generateHelperText()}
                          error={FormData.totalpaybleamount < packageamount}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item xs={12}>
                      <TextField
                        label="Discount / Offer"
                        fullWidth
                        placeholder="Discount / Offer"
                        value={FormData.discount}
                        onChange={handleDiscountChange}
                        helperText={generateHelperTextDIS()}
                        error={
                          parseFloat(FormData.discount) >
                          parseFloat(FormData.totalpaybleamount)
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  sx={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    paddingX: "10px",
                  }}
                >
            
                  {/* Second Row */}
                  <Grid item xs={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          disabled
                          label="Payable Amount"
                          fullWidth
                          value={FormData.payableamount}
                          onChange={(e) =>
                            setFormData({
                              ...FormData,
                              payableamount: e.target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          label="Paying Amount"
                          fullWidth
                          placeholder=" Paying Amount"
                          value={FormData.payingamount}
                          onChange={(e) =>
                            setFormData({
                              ...FormData,
                              payingamount: e.target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="payment-mode-label">
                          Mode Of Payment
                        </InputLabel>
                        <Select
                          label="Mode Of Payment"
                          labelId="payment-mode-label"
                          id="payment-mode"
                          value={FormData.modeofpayment}
                          onChange={(e) =>
                            setFormData({
                              ...FormData,
                              modeofpayment: e.target.value,
                            })
                          }
                          fullWidth
                        >
                          <MenuItem value="paytm">Paytm</MenuItem>
                          <MenuItem value="googlepay">Google Pay</MenuItem>
                          <MenuItem value="phonepe">PhonePe</MenuItem>
                          <MenuItem value="cash">Cash</MenuItem>
                          <MenuItem value="card"> Card</MenuItem>
                          {/* Add more payment options as needed */}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    paddingX: "20px",
                  }}
                >
            
                  {/* Second Row */}
                  <Grid item xs={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <React.Fragment>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoItem components={["DatePicker"]}>
                                <DatePicker
                                  value={FormData.activationdate}
                                  onChange={(date) =>
                                    setFormData({
                                      ...FormData,
                                      activationdate: date,
                                    })
                                  } // Update 'dob' state in formData
                                  label="Activation Date"
                                  format="DD/MM/YYYY"
                                  clearable
                                  slotProps={{
                                    textField: {
                                      error:
                                        submitted && !FormData.activationdate,
                                      helperText:
                                        submitted &&
                                        !FormData.activationdate &&
                                        "Activation Date is required",
                                    },
                                  }}
                                />
                              </DemoItem>
                            </LocalizationProvider>
                          </Box>
                        </React.Fragment>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <React.Fragment>
                          <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoItem components={["DatePicker"]}>
                                <DatePicker
                                  value={FormData.dateofpayment}
                                  onChange={(date) =>
                                    setFormData({
                                      ...FormData,
                                      dateofpayment: date,
                                    })
                                  } // Update 'dob' state in formData
                                  label="Date of Payment"
                                  format="DD/MM/YYYY"
                                  clearable
                                  slotProps={{
                                    textField: {
                                      error:
                                        submitted && !FormData.dateofpayment,
                                      helperText:
                                        submitted &&
                                        !FormData.dateofpayment &&
                                        "Date of Payment is required",
                                    },
                                  }}
                                />
                              </DemoItem>
                            </LocalizationProvider>
                          </Box>
                        </React.Fragment>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    margin: "15px",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "none",
                      whiteSpace: "nowrap",
                      fontWeight: 700,
                    }}
                    type="reset"
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      textTransform: "none",
                      whiteSpace: "nowrap",
                      fontWeight: 700,
                    }}
                    onClick={handleSave}
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Card>
          </div>
        </div>
      </Box>

      <Snackbar
        open={openToast}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          variant="filled"
          onClose={handleToastClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default MemberList;
