

export const retrieveFollwupVisitor = async (
    token,
    userId,
    searchText,
    limit,
    index,
    datelist
  ) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    const raw = JSON.stringify({
      user_id: userId,
      search_text: searchText,
      limit: limit,
      index: index,
      date: {
        type: "1",
        value: datelist, // Assuming datelist is defined correctly
      },
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    try {
      const response = await fetch(
        `${window.constants.api_url}/follow-up/retrive`,
        requestOptions
      );
      const result = await response.json();
      return result;
    } catch (error) {
      throw new Error("Failed to retrieve package: " + error.message);
    }
  };

  export const UpdateFollowUP = async (
    id,
    userToken,
    name,
    phone,
    visit_date,
    followup_date
  ) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${userToken}`);
  
      const requestBody = {
        id: id,
        name: name,
        phone: phone, // Convert to integer
        visit_date: visit_date, // Convert to float
        followup_date: followup_date, // Assuming admission_value is a number
      };
  
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };
  
      const response = await fetch(
        `${window.constants.api_url}/follow-up/update`,
        requestOptions
      );
      const result = await response.json(); // Parse response as JSON
      return result;
    } catch (error) {
      throw new Error("Failed to create package: " + error.message);
    }
  };
  export const createFollowup = async (
    userToken,
    field1,
    field2,
    field3,
    field4
  ) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${userToken}`);
  
      const requestBody = {
        name: field1,
        phone: field2, // Assuming duration is a number
        visit_date: field3, // Assuming package_amount is a number
        followup_date: field4, // Assuming admission_value is a number
      };
  
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };
  
      const response = await fetch(
        `${window.constants.api_url}/follow-up/create`,
        requestOptions
      );
      const result = await response.json(); // Parse response as JSON
      return result;
    } catch (error) {
      throw new Error("Failed to create package: " + error.message);
    }
  };

  export const deleteFollowup = async (token, userId, id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    const raw = JSON.stringify({
      user_id: userId,
      id: id,
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    try {
      const response = await fetch(
        `${window.constants.api_url}/follow-up/delete`,
        requestOptions
      );
      const result = await response.json();
      return result;
    } catch (error) {
      throw new Error("Failed to retrieve package: " + error.message);
    }
  };
  export const AutocompleteFollowup = async (token, userId, searchText) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    const raw = JSON.stringify({
      user_id: userId,
      search_text: searchText,
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    try {
      const response = await fetch(
        `${window.constants.api_url}/follow-up/autocomplete`,
        requestOptions
      );
      const result = await response.json();
      return result;
    } catch (error) {
      throw new Error("Failed to retrieve package: " + error.message);
    }
  };

  export const StatusFollowup = async (token, userId, status, id) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
  
    const raw = JSON.stringify({
      user_id: userId,
      id: id,
      status: status,
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    try {
      const response = await fetch(
        `${window.constants.api_url}/follow-up/change-status`,
        requestOptions
      );
      const result = await response.json();
      return result;
    } catch (error) {
      throw new Error("Failed to retrieve package: " + error.message);
    }
  };
