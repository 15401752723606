// BarChart.jsx

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell
} from 'recharts';
import { Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

import {
  DashboardCount,
  DashboardBarChart
} from "../Apihelper/Dashboard.js";


const BarChartComponent = () => {
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF6384', '#36A2EB', '#4BC0C0', '#9966FF', '#FF9F40', '#FFCD56', '#8A2BE2', '#FF4500'];


  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dashboardData, setDashboardData] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchDashboardData = async () => {
      setLoading(true);
      try {
        const userData = JSON.parse(localStorage.getItem("userdata"));
        const { token, id: userId } = userData;
        const data = await DashboardBarChart(token, userId);
        if (data.code === 200) {
          setData(data.data);
        } else {
          setError(data.message || 'Failed to fetch dashboard data');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchDashboardData();
  }, []);
  const handleDateChange = (date) => {
    setSelectedDate(date);
    // Handle date change logic here
  };

  // const data = [
  //   { name: 'January', blue: 12, deepBlue: 8 },
  //   { name: 'February', blue: 19, deepBlue: 14 },
  //   { name: 'March', blue: 25, deepBlue: 18 },
  //   { name: 'April', blue: 22, deepBlue: 16 },
  //   { name: 'May', blue: 15, deepBlue: 12 },
  //   { name: 'June', blue: 10, deepBlue: 6 },
  //   { name: 'July', blue: 8, deepBlue: 5 },
  //   { name: 'August', blue: 16, deepBlue: 10 },
  //   { name: 'September', blue: 20, deepBlue: 15 },
  //   { name: 'October', blue: 18, deepBlue: 14 },
  //   { name: 'November', blue: 14, deepBlue: 12 },
  //   { name: 'December', blue: 10, deepBlue: 8 },
  // ];

  return (
    <div>
        <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data} margin={{ top: 20, right: 20, left: 20, bottom: 20 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="total" name="Total Amount" barSize={30}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} /> // Apply different colors
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartComponent;
