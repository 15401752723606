import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import AppBar from "@mui/material/AppBar";
import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import { ButtonGroup } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Header from "../Includes/Header";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { DialogContentText } from "@mui/material";
import { EditText } from 'react-edit-text';
import 'react-edit-text/dist/index.css';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // Example icon

import {
  Card,
  Button,
  Grid,
  TextField,
  Snackbar,
  FormControl,
  Alert,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Paper,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Menu from "@mui/material/Menu";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CircularProgress from "@mui/material/CircularProgress";
import json2mq from "json2mq";
import {
  AutocompleteAdminUser,
  StatusAdminUser,
  UpdateAdminUser,
} from "../Apihelper/ApiHelperUser";
import {
  retrieveOfferMember,
  DeleteOfferpackageUser,
  DueOfferpackageUser,
  AutocompleteNonUser,
} from "../Apihelper/ApiOfferPackage";

import { useNavigate } from "react-router-dom";

const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 600,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide the card container on small screens (mobile view)
    },
  },
  card: {
    width: "100%",
    margin: "8px",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px", // Adjust the card width as needed
    },
  },
}));
const drawerWidth = 292;

function OfferPackageMember(props) {
  const navigate = useNavigate();
  const { window1 } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [status, setstatus] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [Role, setRole] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [options, setOptions] = useState([]);
  const [deleteConfirmationId, setDeleteConfirmationId] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // Default to 'success'
  const [fetchdatarow, setetchdatarow] = useState([]); // Default to 'success'
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const dateRef = useRef();
  const [age, setAge] = React.useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [opendue, setOpendue] = useState(false);
  const [selectedDue, setSelectedDue] = useState(null); // To store the current due value for the modal
  const [inputValue, setInputValue] = useState(''); // Input value in modal
  const [currentRowId, setCurrentRowId] = useState(null); // To store the current row id for handling due change

  // Open modal if due is greater than 0
  const handleOpendue = (id, due) => {
    if (due > 0) {
      setOpendue(true);
      setSelectedDue(due);
      setCurrentRowId(id);
    }
  };
  // Close modal

  const handleClosedue = () => {
    setOpendue(false);
    setInputValue('');
  };
  // Handle input change
  const handleInputChangedue = (event) => {
    let value = event.target.value;

    // Ensure the value is within the allowed range (min = 0, max = selectedDue)
    if (value > selectedDue) {
      value = selectedDue; // Limit the value to the maximum (due amount)
    } else if (value < 0) {
      value = 0; // Prevent negative values
    }

    setInputValue(value);
  };

  // Handle submit
  const handleSubmit = () => {
    handleDueChange(currentRowId, inputValue); // Call the API function with the new value
    handleClosedue();
  };

  const [editedData, setEditedData] = useState({
    id: "",
    user_name: "",
    fullname: "",
    email: "",
    phone: "",
    role: "",
  });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);


  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 600,
    })
  );

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const handleToastClose = () => {
    setOpenToast(false);
  };
  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenToast(true);
  };
  const container =
    window1 !== undefined ? () => window().document.body : undefined;

  // useEffect(() => {
  //   // Perform localStorage action
  //   const item = localStorage.getItem('userdata')
  //   if (item ==null){
  //      setredirectpage(true);
  //   }
  //   //console.log("tapan"+item);
  // }, [])
  // if (redirectpage) {

  //   return redirect('/');
  // }



  const handleToggle = (id, status) => {
    setOpenConfirmation(true);
    const newStatus = status === 1 ? 0 : 1;
    setstatus(newStatus);
    setDeleteConfirmationId(id);
    setIsActive(newStatus === 0);
  };

  const handleConfirm = async () => {
    // setIsActive(!isActive);
    // setOpenConfirmation(false);

    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id;

      const res = await StatusAdminUser(
        userToken,
        userId,
        status,
        deleteConfirmationId
      );

      if (res.code == "200") {
        handleSnackbarOpen(res.message, "success");
        setIsActive(!isActive);
        setOpenConfirmation(false);
        setOpenDeleteConfirmation(false);
        fetchData();
      } else {
        handleSnackbarOpen(res.message, "error");
        //  console.error(error); // Handle error
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };
  const handleCancel = () => {
    setOpenConfirmation(false);
  };
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [dueValues, setDueValues] = useState([]); // Initialize state for due values
  const [adddueValues, setaddDueValues] = useState([100, 50, 75]);
 
  const fetchData = async (value = "") => {
    setLoading(true);
    setHasMore(true);
    try {
      const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const userId = userDataObj.id; // Assuming you have a userId in userdata
      const searchText = value; // Example search text
      const limit = 10; // Example limit
      const index = 0; // Example index
      const res = await retrieveOfferMember(
        userToken,
        userId,
        searchText,
        limit,
        index
      );

      if (res.code == "200") {
        setetchdatarow(res.data);
        setDueValues(res.data.map(row => row.due)); // Extract 'due' from each row
    
       
        console.log(res.data);
        setLoading(false);
      } else {
        handleSnackbarOpen(res.message, "error");
        // console.error(error); // Handle error
      }
    } catch (error) {
      console.error(error);
      // Handle errors
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleLoadMore = async () => {
    // Calculate the next index based on the number of items loaded so far
    const nextIndex = fetchdatarow.length;

    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);

    try {
      const userToken = userDataObj.token;
      const userId = userDataObj.id;
      const searchText = "";
      const limit = 10;

      const res = await retrieveOfferMember(
        userToken,
        userId,
        searchText,
        limit,
        nextIndex
      );

      if (res.code == "200") {
        // Append the newly loaded data to the existing data
        setetchdatarow((prevResults) => [...prevResults, ...res.data]);
        setDueValues((prevDueValues) => [
          ...prevDueValues,
          ...res.data.map(row => row.due) // Extract 'due' from the new rows
        ]);
        // Check if there are more results available
        if (res.data.length >= 0 && res.data.length < 10) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSearchChange = (event, value) => {
    const searchText = value || ""; // If value is undefined or empty, set searchText to empty string
    // setSearchText(searchText);
    // Call fetchData function here with the updated searchText
    fetchData(searchText);
  };

  const handleDelete = (id) => {
    handleClose();
    setDeleteConfirmationId(id);
    setOpenDeleteConfirmation(true, id);
  };
  React.useEffect(() => {
    const storedData = localStorage.getItem("userdata");
    if (!storedData) {
      // User is not logged in, no need to redirect
      navigate('/');
    }
    
  }, []);
  const handleConfirmDelete = async (id) => {
    // setSnackbarMessage('package deleted successfully');
    // setSnackbarSeverity('error');
    // setOpenDeleteConfirmation(false);
    // setOpenToast(true); // Show toast message

    if (deleteConfirmationId !== null) {
      try {
        const userData = localStorage.getItem("userdata");
        const userDataObj = JSON.parse(userData);
        const userToken = userDataObj.token;
        const userId = userDataObj.id;

        const res = await DeleteOfferpackageUser(
          userToken,
          userId,
          deleteConfirmationId
        );

        if (res.code == "200") {
          handleSnackbarOpen(res.message, "success");
          setOpenDeleteConfirmation(false);
          fetchData();
        } else {
          handleSnackbarOpen(res.message, "error");
          //  console.error(error); // Handle error
        }
      } catch (error) {
        console.error(error);
        // Handle errors
      }
    }
  };

  const handleCancelDelete = () => {
    setOpenDeleteConfirmation(false);
  };
  const handleInputChange = async (value) => {
    if (value.length >= 3) {
      try {
        const userData = localStorage.getItem("userdata");
        const userDataObj = JSON.parse(userData);
        const userToken = userDataObj.token;
        const userId = userDataObj.id; // Assuming you have a userId in userdata
        const searchText = value; // Example search text

        const res = await AutocompleteNonUser(userToken, userId, searchText);

        if (res.code == "200") {
          const names = res.data.map((item) => item.search_text);
          setOptions(names);
        } else {
          handleSnackbarOpen(res.message, "error");
          //  console.error(error); // Handle error
        }
      } catch (error) {
        console.error(error);
        // Handle errors
      }
    }
  };
  const handleEditModalOpen = (id, fullname, email, phone, user_name, role) => {
    setEditedData({
      id: id,
      fullname: fullname,
      email: email,
      phone: phone,
      user_name: user_name,
      role: role,
    });
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    // Reset confirm password error when password is changed
    setConfirmPasswordError("");
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    // Check if confirm password matches password
    if (event.target.value !== password) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleEditSubmit = async () => {
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return;
    }

    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;

    try {
      const res = await UpdateAdminUser(
        editedData.id, // Assuming id is needed for updating
        userToken,
        editedData, // Pass the entire editedData object
        confirmPassword // Pass confirmPassword separately
      );

      if (res.code == "200") {
        handleSnackbarOpen(res.message, "success");
        setConfirmPassword("");
        setPassword("");
        setEditedData({
          id: "",
          user_name: "",
          fullname: "",
          email: "",
          phone: "",
          role: "",
        });
        fetchData();
      } else {
        handleSnackbarOpen(res.message, "error");
      }
    } catch (error) {
      console.error(error);
    }

    setEditModalOpen(false);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleDueChange = async (id,value) => {
    const updatedValues = dueValues.map((due, index) => (index === id - 1 ? value : due));
    setaddDueValues(updatedValues);
   
      try {
        const userData = localStorage.getItem("userdata");
        const userDataObj = JSON.parse(userData);
        const userToken = userDataObj.token;
        const userId = userDataObj.id;

        const res = await DueOfferpackageUser(
          userToken,
          userId,
          id,
          value
        );

        if (res.code == "200") {
          handleSnackbarOpen(res.message, "success");
          // setOpenDeleteConfirmation(false);
          fetchData();
        } else {
          handleSnackbarOpen(res.message, "error");
          //  console.error(error); // Handle error
        }
      } catch (error) {
        console.error(error);
        // Handle errors
      }
   
  };

  return (
    <Box>
      <Box>
        <div>
          <div>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography variant="h5" component="h6">
                  Members
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  onClick={() => navigate("/addnonmember")}
                  sx={{
                    textTransform: "none",
                    whiteSpace: "nowrap",
                    fontWeight: 700,
                  }}
                >
                  New Member
                </Button>
              </Box>
            </Box>

            <Box>
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={options}
                onInputChange={(event, value) => handleInputChange(value)} // Pass searchText to handleInputChange
                onChange={(event, value) => handleSearchChange(event, value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      width: 300,
                      "& .MuiInputLabel-root": {
                        textAlign: "center", // Center align the label text
                      },
                    }}
                    label="Search input"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
                sx={{
                  "& .MuiInputBase-root": {
                    alignItems: "center",
                    height: "40px",
                  },
                }}
              />
            </Box>

            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
                margin: "20px 0px",
              }}
            >
              {loading ? ( // Display spinner if loading is true
                <div style={{ textAlign: "center", margin: "50px auto" }}>
                  <CircularProgress />
                </div>
              ) : (
                // Display table if loading is false
                <Table size="small" aria-label="simple table">
                  <TableHead sx={{ backgroundColor: "#B1ADAD" }}>
                    <TableRow
                      sx={{
                        backgroundColor: "#2f80ed",
                      }}
                    >
                      <TableCell
                      
                        sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }}
                      >
                        NAME
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }}
                      >
                        NON-MEMBER ID
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }}
                      >
                        INVOICE
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }}
                      >
                        PHONE
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }}
                      >
                        EMAIL &nbsp;
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }}
                      >
                        PACKAGE&nbsp;
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }}
                      >
                        DUE&nbsp;
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          color: "#fff",
                          fontWeight: "bold",
                          borderBottom: "none",
                        }}
                      >
                        ACTION&nbsp;
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fetchdatarow.map((row, indexx) => (
                      <>
                   
                      <TableRow
                        key={indexx}
                        sx={{
                          "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                          "&:hover": {
                            backgroundColor: "#e3f2fd",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <TableCell>
                          {row.name}
                        </TableCell>
                        <TableCell>
                         {row.membership_number}
                        </TableCell>
                        <TableCell align="left">{row.invoice_number}</TableCell>
                        <TableCell align="left">{row.phone}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left">{row.package_name}</TableCell>
                        <TableCell align="left">
                        <Box 
          key={row.id} 
          sx={{ display: "flex", alignItems: "center", marginBottom: '16px' }}
        >
          {/* Due value */}
          <span>{row.due}</span>
          
          {/* Icon button */}
          <IconButton
            color="primary"
            onClick={() => handleOpendue(row.id, dueValues[indexx])}
            disabled={dueValues[indexx] === 0}
          >
            <AttachMoneyIcon />
          </IconButton>
        </Box>
                        

      </TableCell>

                        <TableCell>
                          <>
                          <Box 
        
          sx={{ display: "flex", alignItems: "center", marginBottom: '16px' }}
        >
                            <IconButton
                              color="primary"
                              aria-label="edit"
                              onClick={() =>
                                handleEditModalOpen(
                                  row.id,
                                  row.name,
                                  row.email,
                                  row.phone,
                                  row.user_name,
                                  row.role
                                )
                              }
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              color="error"
                              aria-label="delete"
                              onClick={() => handleDelete(row.id)} // Pass the id or any other identifier if needed
                            >
                              <DeleteIcon />
                            </IconButton>
                            </Box>
                          </>

                        
                        </TableCell>
                      </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleLoadMore}
                disabled={
                  !hasMore ||
                  (fetchdatarow.length < 10 && fetchdatarow.length !== 0)
                }
                sx={{ fontWeight: "bold", textTransform: "none" }}
              >
                {fetchdatarow.length === 0 || fetchdatarow.length < 10
                  ? "No More Records"
                  : "Load More"}
              </Button>
            </div>
          </div>
        </div>
      </Box>
      <Dialog
        open={openDeleteConfirmation}
        onClose={handleCancelDelete}
        fullWidth="sm"
      >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this package?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<CancelIcon />}
            sx={{ fontWeight: "bold", textTransform: "none" }}
            onClick={handleCancelDelete}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            sx={{ fontWeight: "bold", textTransform: "none" }}
            variant="contained"
            startIcon={<DeleteIcon />}
            color="error"
          >
            {" "}
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openToast}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          variant="filled"
          onClose={handleToastClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={openConfirmation} onClose={handleCancel}>
        <DialogTitle>{isActive ? "Deactivate" : "Activate"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isActive
              ? "Are you sure you want to deactivate this item?"
              : "Are you sure you want to activate this item?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontWeight: "bold", textTransform: "none" }}
            startIcon={<CancelIcon />}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            sx={{ fontWeight: "bold", textTransform: "none" }}
            variant="contained"
            startIcon={<CheckCircleIcon />}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editModalOpen} onClose={handleEditModalClose}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          {/* Edit input fields */}
          <FormControl fullWidth margin="normal">
            <TextField
              label="Name"
              type="text"
              value={editedData.fullname}
              onChange={(e) =>
                setEditedData({ ...editedData, fullname: e.target.value })
              }
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Email"
              type="email"
              value={editedData.email}
              onChange={(e) =>
                setEditedData({ ...editedData, email: e.target.value })
              }
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Phone"
              type="number"
              value={editedData.phone}
              onChange={(e) =>
                setEditedData({ ...editedData, phone: e.target.value })
              }
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontWeight: "bold", textTransform: "none" }}
            startIcon={<CancelIcon />}
            onClick={handleEditModalClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ fontWeight: "bold", textTransform: "none" }}
            startIcon={<CheckCircleIcon />}
            onClick={handleEditSubmit}
            variant="contained"
            color="primary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
  
      <Dialog open={opendue} onClose={handleClosedue}>
        <DialogTitle>Enter Due Payment</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Enter amount"
            type="number"
            value={inputValue}
            onChange={handleInputChangedue}
            fullWidth
            inputProps={{
              min: 0,
              max: selectedDue, // Set the maximum value equal to the due amount
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosedue}   sx={{ fontWeight: "bold", textTransform: "none" }}
            startIcon={<CancelIcon />} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit}   variant="contained"   startIcon={<CheckCircleIcon />} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default OfferPackageMember;
