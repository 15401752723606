import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import * as Yup from "yup";
import "./SignIn.css";
import { Link } from "react-router-dom";
import {
  Grid,
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  Toolbar,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

import EmailIcon from "@mui/icons-material/Email";
// import image1 from './image-1.png';

import { ErrorMessage, Field, Form, Formik } from "formik";

// import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

// import { newPassword,ResetLink } from "../ApiHelper/Profile";
// const validationSchema = Yup.object({
//     username: Yup.string().trim().required('Username is required'),
//     password: Yup.string().required('Password is required'),
// });
const ForgetPassword = () => {
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);
  const tokenFromUrl = urlParams.get("token");

  console.log("urlParams", urlParams);
  console.log("tokenFromUrl", tokenFromUrl);
  const [logoutMessage, setLogoutMessage] = useState("");

  //   useEffect(() => {
  //     const storedMessage = localStorage.getItem("logoutmessage");
  //     if (storedMessage) {
  //       setLogoutMessage(storedMessage);

  //       localStorage.removeItem("logoutmessage");
  //     }
  //   }, []);
  console.log(logoutMessage);
  const [loading, setLoading] = useState(false);
  const [toastdisplay, settoastdisplay] = useState("no");
  const [toastmessage, settoastmessage] = useState("");
  const [toastcolor, settoastcolor] = useState("info");
  const [severity, setseverity] = useState("success");
  const [secondSnackbarOpen, setSecondSnackbarOpen] = useState(true);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const initialValues = {
    email: "",
  };

  const paperStyle = {
    padding: "15px 29.5px",
    height: "auto",
    width: "365px",
    margin: "25px auto 15px auto",
    gap: 32,
    borderRadius: "10px",
    backgroundColor: "rgba(255, 255, 255, 0.16)",
    margintop: "90px",
  };

  const btnStyle = {
    marginTop: "30px",
    marginBottom: "17px",
  };
  const padStyle = {
    padding: "25px 0 0 0",
    font: "500 24px/26px Roboto, sans-serif",
  };
  const mobilepadStyle = {
    padding: "5px 0 0 0",
    font: "300 18px/20px Roboto, sans-serif",
  };
  const inputStyle = {
    borderRadius: "4px",
    gap: 32,
  };
  const margintop = {
    marginTop: 30,
  };
  const forgotPass = {
    textDecoration: "none",
    fontFamily: "Roboto, sans-serif",
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };
  const [errorCheck, setErrorCheck] = useState(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);
    setLoading(true);

    try {
      const res = await ResetLink(values.email);
      //  console.log(' bef inside')
      if (res.code == "200") {
        settoastdisplay("yes");
        settoastmessage(<span>Reset Password link sent to your Email..</span>);
        settoastcolor("info");
        console.log(res.data);
        setSnackbarOpen(true);
        setLoading(false);
        // Use setTimeout to navigate after 10 seconds
        setTimeout(() => {
          navigate("/", { state: { showSnackbar: true } });
        }, 10000); // 10000 milliseconds = 10 seconds
      } else {
        settoastdisplay("yes");
        settoastmessage(res.message);

        settoastcolor("error");
        setSnackbarOpen(true);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  // useEffect(() => {
  //     // Retrieve the message from local storage

  //     localStorage.removeItem('message');

  // }, []);
  const mobileMediaQuery = "@media (max-width: 500px)";
  return (
    <>
      <Grid className="home-1920-login">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
            marginRight: "10px",
            marginLeft: "10px",
          }}
        ></Box>
        <Grid sx={{ backgroundColor: "var(--Neutral-grey, #EFF1F3)" }}>
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values }) => (
              <Form>
                <Paper
                  elevation={8}
                  className="paper_style"
                  sx={{
                    ...paperStyle,
                    [mobileMediaQuery]: {
                      width: "95%",
                      height: "350px",
                      margin: "50% 10px 10px 10px",
                    },
                  }}
                >
                  <Grid align="center">
                    <div
                      className="strong_password"
                      style={{ padding: "15px 0px" }}
                    >
                      Enter the email address associated with your account, and
                      we will send you a link to reset your password.
                    </div>
                  </Grid>

                  <div className="password_field">
                    <Field
                      as={TextField}
                      name="email"
                      helperText={<ErrorMessage name="Enter your email" />}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Enter your email"
                      fullWidth
                    />
                  </div>

                  <div className="submit_btn">
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        backgroundColor: loading
                          ? "#616161"
                          : btnStyle.backgroundColor,
                      }}
                      fullWidth
                      disabled={loading}
                    >
                      {" "}
                      {loading && (
                        <CircularProgress
                          size={16}
                          style={{
                            position: "flex-start",
                            left: "70%",
                            marginLeft: "-12px",
                            color: "white",
                          }}
                        />
                      )}
                      Submit
                    </Button>
                  </div>
                </Paper>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>

      <Snackbar
        mode="outlined"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center", // Center bottom
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: toastcolor === "black",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          message={
            <span style={{ display: "flex", alignItems: "center" }}>
              {toastmessage}
            </span>
          }
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
      {logoutMessage && (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={secondSnackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSecondSnackbarOpen(false)}
        >
          <SnackbarContent
            style={{
              backgroundColor: "info",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            message={logoutMessage}
            action={[
              <IconButton
                key="close"
                color="inherit"
                onClick={() => setSecondSnackbarOpen(false)}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </Snackbar>
      )}
    </>
  );
};
export default ForgetPassword;
