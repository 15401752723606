export const createNewMember = async (userToken, UseradminData) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const formData = new FormData();
    formData.append("membership_number", UseradminData.membership_number);
    formData.append("image", UseradminData.selectedFile);
    formData.append("package_id", UseradminData.package);
    formData.append("name", UseradminData.name);
    formData.append("email", UseradminData.email);
    formData.append("phone", UseradminData.phone);
    formData.append("alternate_phone", UseradminData.alter_phone);
    formData.append("identification_type", UseradminData.identification_type);
    formData.append("identification_id", UseradminData.identification_id);
    formData.append("dob", UseradminData.dob);
    formData.append("address", UseradminData.address);
    formData.append("height", UseradminData.height);
    formData.append("weight", UseradminData.weight);
    formData.append("sex", UseradminData.sex);
    formData.append("payble_amount", UseradminData.payableamount);
    formData.append("total_payble_amount", UseradminData.totalpaybleamount);
    formData.append("mode_of_payment", UseradminData.modeofpayment);
    formData.append("paying_amount", UseradminData.payingamount);
    formData.append("date_of_payment", UseradminData.dateofpayment);
    formData.append("start_date", UseradminData.activationdate);
    formData.append("admission_value_id", UseradminData.membership);
    formData.append("admission_value", UseradminData.membershipvalue);
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    const response = await fetch(
      `${window.constants.api_url}/create-member`,
      requestOptions
    );
    const result = await response.json(); // Parse response as JSON
    return result;
  } catch (error) {
    throw new Error("Failed to create member: " + error.message);
  }
};
export const retrieveGymMember = async (
  token,
  userId,
  searchText,
  limit,
  index
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    search_text: searchText,
    limit: limit,
    index: index,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/retrive-member`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve user: " + error.message);
  }
};
export const AutocompleteMember = async (token, userId, searchText) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    search_text: searchText,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/autocomplete-member`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};
export const AutoMembershipNumber = async (token, userId) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/membership-number`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};
export const StatusMember = async (token, userId, status, id) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    id: id,
    status: status,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/member-status`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};
export const DeleteJoinMember = async (token, userId, id) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    id: id,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/delete-member`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to deletemember: " + error.message);
  }
};

export const PackageDropdown = async (token, userId) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/drop-down-package`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};

export const PackageDropdownvalue = async (token, userId, id,conversation_id = null) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    id: id,
    member_id: conversation_id || null,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/package-value`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};

export const retrievePaymentHistory = async (
  token,
  userId,
  conversationId,
  searchText,
  limit,
  index,
  datelist, fromDate, toDate
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const payload = {
    user_id: userId,
    search_text: searchText,
    limit: limit,
    member_id:conversationId,
    index: index,
    date: {
      type: "1",
      value: datelist, // Assuming datelist is defined correctly
    },
  };
  
  // Check if fromDate and toDate are not empty and modify the payload accordingly
  if (fromDate !== "" && toDate !== "") {
    payload.date = {
      type: "2",
      value: `${fromDate},${toDate}`, // Combine the two dates into a single string
    };
  }
  
  // Convert the payload object to a JSON string for the API call
  const raw = JSON.stringify(payload);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/payment-history`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve user: " + error.message);
  }
};

export const AutocompletePaymentsHistory = async (
  token,
  userId,
  searchText
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    search_text: searchText,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/all-payments-auto-complete`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};


export const ChangePackageDropdownValue = async (token,userId,package_id,member_id) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    package_id:package_id,
    member_id:member_id,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/get/package/value`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};

export const MembeChangePackage = async (token, userId, conversation_id,FormData) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    member_id: conversation_id,
    package_id: FormData.package_id,
    offer:FormData.offer,
    payble_amount: FormData.payble_amount,
    total_payble_amount: FormData.total_payble_amount,
    paying_amount: FormData.paying_amount,
    mode_of_payment: FormData.mode_of_payment,
    date_of_payment: FormData.date_of_payment,
    start_date: FormData.start_date,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/change-package`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};


