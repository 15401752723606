import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { TextField, List, Autocomplete, ListItem, Divider } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TodayIcon from '@mui/icons-material/Today';
import InputAdornment from '@mui/material/InputAdornment';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CloseIcon from '@mui/icons-material/Close';

function DateFilter(props, ref) {
  const { onApply, onApplycustomdate } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsShowMore(false);
  };

  const [isShowMore, setIsShowMore] = useState(false);
  const [buttonText, setButtonText] = useState('Date Range');
  const [selectedValue, setSelectedValue] = useState('');

 

  const showMore = () => {
    setIsShowMore(!isShowMore);
    // Reset the button text
  };

  const handleCustomDateApply = () => {
    // Apply custom date range logic here
    setButtonText('Custom Date'); // Change the button text to 'Custom Date' when applied
    setIsShowMore(false);
    setAnchorEl(null);
    const { formattedDate: formattedFromDate } = formatDate(fromDate);
    const { formattedDate: formattedToDate } = formatDate(toDate);
    
    console.log(formattedFromDate,formattedToDate)
  onApplycustomdate({ fromDateval: formattedFromDate, toDateval: formattedToDate})
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const modifyDateText = [
    { label: 'Today', value: 'today' },
    { label: 'Last 7 Days', value: '7days' },
    { label: 'Last 30 Days', value: '30days' },
    { label: 'This year', value: 'thisYear' },
    { label: 'Last Year', value: 'lastYear' },
  ];
  const handleListItemClick = (item, value) => {
    setButtonText(item);
    setSelectedValue(value);
    console.log(value)
   onApply(value);
    setAnchorEl(null);
  };
  const formatDate = (dateInput) => {
    if (!dateInput) {
      return "Date not selected"; // Default message if no date is provided
    }
  
    // Create a new Date object from the input
    const date = new Date(dateInput);
  
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid date"; // Return an error message for invalid dates
    }
  
    // Format the date as 'YYYY-MM-DD' for easier manipulation
    const formattedDate = date.toISOString().split('T')[0]; // e.g., "2024-01-01"
    
    // Optionally format for display, e.g., "dd MMM YYYY"
    const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Asia/Kolkata' };
    const displayDate = date.toLocaleDateString('en-US', options); // e.g., "January 1, 2024"
  
    return {
      formattedDate,
      displayDate,
    };
  };
  
 
  const formattedResult = formatDate("Tue, 31 Oct 2023 18:30:00 GMT"); // Output: Sat Nov 25 2023 00:00:00 GMT+0530 (India Standard Time)
  const handleResetClick = () => {
    setFromDate(null);
    setToDate(null);  
     setSelectedValue(null);
    setButtonText('Date Range');
  };

  useEffect(() => {
    // Use the ref to expose the handleResetClick function
    if (ref) {
      ref.current = {
        handleResetClick,
      };
    }
  }, [ref]);
  const DateApplyRevert = () =>{
    setButtonText('Date Range');
                        setAnchorEl(null);
                        setAnchorEl(false);
                        setIsShowMore(false);
  }
  return (
    <div>
     <Button
        onClick={handleClick}
        sx={{
          '&:hover': {
            backgroundColor: '#2f80ed',
          },
          backgroundColor: '#2f80ed',
          color: 'white',
          textTransform: 'none',
          marginLeft: '5px',
          height:'50px'
        
        }}
      >
        {buttonText}{' '}
        {buttonText === 'Date' ? (
          <CalendarMonthIcon sx={{ height: '17px', width: '17px', marginLeft: '10px', marginTop: '-3px' }} />
        ) : (
          <CheckIcon sx={{ height: '17px', width: '17px', marginLeft: '10px', marginTop: '-3px' }} />
        )}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography>
          <Card
            variant="outlined"
            style={{
              display: 'flex',
            }}
          >
            <div>
              <div style={{ display: 'flex' }}>
                <CardContent style={{ display: 'flex' }}>
                  <List>
                    {modifyDateText.map((item) => (
                      <ListItem sx={{ margin: '0px', padding: '0px' }}>
                        <Button
                          sx={{ width: '100%', justifyContent: 'left' }}
                          onClick={() => handleListItemClick(item.label,item.value)}
                        >
                          {item.label}
                        </Button>
                      </ListItem>
                    ))}
                    <ListItem sx={{ margin: '0px', padding: '0px' }}>
                      <Button sx={{ width: 'max-content' }} onClick={showMore}>
                        Custom date range
                      </Button>
                    </ListItem>
                  </List>
                  <Divider />
                </CardContent>
                <Divider orientation="vertical" flexItem />
                {isShowMore && (
                  <div>
                    <Divider orientation="vertical" flexItem />
                    <CardContent style={{ flex: 1 }}>
                      <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker']}>
                            <DatePicker label="From"   value={fromDate}
      onChange={(date) => setFromDate(date)}/>
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker label="To"  value={toDate}
      onChange={(date) => setToDate(date)}/>
                        </DemoContainer>
                      </LocalizationProvider>
                    </CardContent>
                  </div>
                )}
              </div>
              {isShowMore && (
                <div>
                  <Divider orientation="horizontal" sx={{ marginBottom: '20px', }} flexItem />
                  <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                      variant="outlined"
                      sx={{ margin: '10px',textTransform: 'none', fontWeight: 'bold', }}
                      onClick={() => {
                        setButtonText('Date Range');
                        setAnchorEl(null);
                        setIsShowMore(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button variant="contained" sx={{ textTransform: 'none', fontWeight: 'bold',margin: '10px' }} onClick={handleCustomDateApply}>
                      Apply
                    </Button>
                  </Box>
                </div>
              )}
            </div>
          </Card>
        </Typography>
      </Popover>
    </div>
  );
}

export default React.forwardRef(DateFilter);
