export const DashboardCount = async (token, userId) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
  });

  const requestOptions = {
    method: "GET",
    headers: myHeaders,

    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/all-dashboard-details`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve DashboardCount: " + error.message);
  }
};

export const DashboardpieChart = async (token, userId) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/invoice/by/category`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve DashboardCount: " + error.message);
  }
};

export const DashboardBarChart = async (token, userId) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/monthly/payment/summary`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve DashboardCount: " + error.message);
  }
};
export const getinvoiceBYid = async (token, userId,invoice_number,) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
  
    invoice_number:invoice_number
   
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/get-invoice-by-id`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve DashboardCount: " + error.message);
  }
};

export const DashboardDuepieChart = async (token, userId) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/due/payment/summary`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve DashboardCount: " + error.message);
  }
};


export const getUpcomingExpire = async (token, per_page, search_text) => {
  // Create headers for the request
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  // Prepare the request body
  const raw = JSON.stringify({
    per_page: per_page,
    search_text: search_text,
  });

  // Define request options
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    // Send the API request using fetch
    const response = await fetch(
      `${window.constants.api_url}/memberships/upcoming-expiry`, // Fixed the double slash
      requestOptions
    );

    // Check if the response is OK (status code 200-299)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    // Catch any errors and rethrow a custom message
    throw new Error("Failed to retrieve upcoming membership expiry data: " + error.message);
  }
};


export const getPendingDue= async (token, per_page, search_text) => {
  // Create headers for the request
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  // Prepare the request body
  const raw = JSON.stringify({
    per_page: per_page,
    search_text: search_text,
  });

  // Define request options
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    // Send the API request using fetch
    const response = await fetch(
      `${window.constants.api_url}/members/total-due`, // Fixed the double slash
      requestOptions
    );

    // Check if the response is OK (status code 200-299)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    // Catch any errors and rethrow a custom message
    throw new Error("Failed to retrieve upcoming membership expiry data: " + error.message);
  }
};

export const getActivePT= async (token, per_page, search_text) => {
  // Create headers for the request
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  // Prepare the request body
  const raw = JSON.stringify({
    per_page: per_page,
    search_text: search_text,
  });

  // Define request options
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    // Send the API request using fetch
    const response = await fetch(
      `${window.constants.api_url}/active-pt-payments`, // Fixed the double slash
      requestOptions
    );

    // Check if the response is OK (status code 200-299)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Parse the JSON response
    const result = await response.json();
    return result;
  } catch (error) {
    // Catch any errors and rethrow a custom message
    throw new Error("Failed to retrieve upcoming membership expiry data: " + error.message);
  }
};







