export const loginUser = async (userData) => {
  const BaseURL = `${window.constants.api_url}/admin-login`;

  try {
    const response = await fetch(BaseURL, {
      method: "POST",
      body: userData, // Pass the FormData object directly as the body
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error:", error);
    return { code: "error", message: "An error occurred" };
  }
};

export const createPackage = async (
  userToken,
  field1,
  field2,
  field3,
  field4
) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const requestBody = {
      name: field1,
      duration: field2, // Assuming duration is a number
      package_amount: field3, // Assuming package_amount is a number
      admission_value: field4, // Assuming admission_value is a number
    };

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(requestBody),
      redirect: "follow",
    };

    const response = await fetch(
      `${window.constants.api_url}/create-package`,
      requestOptions
    );
    const result = await response.json(); // Parse response as JSON
    return result;
  } catch (error) {
    throw new Error("Failed to create package: " + error.message);
  }
};
export const UpdateMemberPackage = async (
  id,
  userToken,
  name,
  duration,
  packageAmount,
  admissionValue
) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const requestBody = {
      id: id,
      name: name,
      duration: parseInt(duration), // Convert to integer
      package_amount: parseFloat(packageAmount), // Convert to float
      admission_value: parseFloat(admissionValue), // Convert to float
    };

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(requestBody),
      redirect: "follow",
    };

    const response = await fetch(
      `${window.constants.api_url}/update-package`,
      requestOptions
    );
    const result = await response.json(); // Parse response as JSON
    return result;
  } catch (error) {
    throw new Error("Failed to create package: " + error.message);
  }
};

export const retrievePackage = async (
  token,
  userId,
  searchText,
  limit,
  index
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    search_text: searchText,
    limit: limit,
    index: index,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/retrive-package`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};
export const retrieveTrainerPackage = async (
  token,
  userId,
  searchText,
  limit,
  index
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    search_text: searchText,
    limit: limit,
    index: index,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/retrive-trainer-package`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};
export const AutocompletePackage = async (token, userId, searchText) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    search_text: searchText,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/auto-complete`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};
export const DeletePackage = async (token, userId, id) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    id: id,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/delete-package`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};
export const createTrainerPackage = async (
  userToken,
  field1,
  field2,
  field3
) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const requestBody = {
      name: field1,
      duration: field2, // Assuming duration is a number
      package_amount: field3, // Assuming package_amount is a number
    };

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(requestBody),
      redirect: "follow",
    };

    const response = await fetch(
      `${window.constants.api_url}/create-trainer-package`,
      requestOptions
    );
    const result = await response.json(); // Parse response as JSON
    return result;
  } catch (error) {
    throw new Error("Failed to create package: " + error.message);
  }
};
export const UpdateTrainerPackage = async (
  id,
  userToken,
  name,
  duration,
  packageAmount
) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    const requestBody = {
      package_id: id,
      name: name,
      duration: parseInt(duration), // Convert to integer
      package_amount: parseFloat(packageAmount), // Convert to float
    };

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(requestBody),
      redirect: "follow",
    };

    const response = await fetch(
      `${window.constants.api_url}/update-trainer-package`,
      requestOptions
    );
    const result = await response.json(); // Parse response as JSON
    return result;
  } catch (error) {
    throw new Error("Failed to create package: " + error.message);
  }
};
export const AutocompleteTrainerPackage = async (token, userId, searchText) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    search_text: searchText,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/auto-complete-trainer`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};
export const DeleteTrainerPackage = async (token, userId, id) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    id: id,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/delete-trainer-package`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};

export const StatusTrainerPackage = async (token, userId, status, id) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    id: id,
    status: status,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/trainer-package-status`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};
export const StatusMemberPackage = async (token, userId, status, id) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    user_id: userId,
    id: id,
    status: status,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/package-status`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    throw new Error("Failed to retrieve package: " + error.message);
  }
};
